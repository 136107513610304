import React from "react";
import { Box, Fade } from "@mui/material";

interface PageTransitionProps {
  children: JSX.Element;
}

/**
 * A reusable component that fades the given children in, without delay.
 *
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
export default function PageTransition({ children }: PageTransitionProps): JSX.Element {
  return (
    <Box
      className={
        "w-100 h-100 d-flex justify-content-center align-items-center position-relative p-5"
      }
    >
      <Fade in={true} timeout={400}>
        {children}
      </Fade>
    </Box>
  );
}
