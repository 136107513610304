import React from "react";
import { Box, CircularProgress } from "@mui/material";

/**
 * Component that shows a loading symbol, with added padding.
 *
 * This component is mostly used within the tables.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Spinner(): JSX.Element {
  return (
    <Box className={"pt-5"}>
      <CircularProgress />
    </Box>
  );
}
