import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast: any) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export function succeedAlert() {
  Toast.fire({
    icon: "success",
    title: "Processed successfully",
  });
}

export function errorAlert(errorMessage: string) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: errorMessage,
  });
}

export function confirmAlert(callback: Function) {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes!",
  }).then((result) => {
    if (result.value) {
      callback();
    }
  });
}
