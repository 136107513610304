import React, { useState } from "react";
import ManageTrain from "./manageTrain/ManageTrain";
import TrainService from "../../api/trains";
import { useAuth0 } from "../../react-auth0-spa";
import { confirmAlert, errorAlert, succeedAlert } from "../../utils/Alerts";
import { Box } from "@mui/material";
import TitleBar from "../../components/TitleBar/TitleBar";
import TrainTable, { Train } from "./innerbar/table/TrainTable";
import { useFetch } from "../../effects";
import { PageTransition } from "../../components";

export default function TrainPicker(): JSX.Element {
  const [isChanged, setChanged] = useState<boolean>(false);
  const [selectedTrain, setSelectedTrain] = useState<Train | null>(null);
  const [trains, setTrains] = useState<{ data: Train[]; error: any; isFetching: boolean }>({
    data: [],
    error: null,
    isFetching: false,
  });
  const { getTokenSilently } = useAuth0();

  useFetch(TrainService.getTrains, setTrains);

  function onSave(train: Train) {
    setTrains((prevState) => ({ ...prevState, data: [...prevState.data, train] }));
  }

  function onUpdate(train: Train) {
    let newTrainsList = trains.data.filter((t) => t.id !== train.id);
    newTrainsList.push(train);
    newTrainsList.sort(
      (a: Train, b: Train) =>
        +new Date(b.scheduledDateTimeAtTransfer) - +new Date(a.scheduledDateTimeAtTransfer),
    );
    setTrains({ ...trains, data: newTrainsList });
  }

  const onDeleteTrain = (train: Train) => {
    confirmAlert(async () => {
      try {
        const result = await TrainService.deleteTrain(Number(train.id), await getTokenSilently!());
        if (result.data) {
          let newTrainsList = trains.data.filter((t) => t.id !== train.id);
          setTrains((prevState) => ({ ...prevState, data: [...newTrainsList] }));
          succeedAlert();
        } else {
          errorAlert(result?.message);
        }
      } catch (e: any) {
        errorAlert("Failed delete request " + e.message);
      }
    });
  };

  function showSuccess() {
    onHide();
    succeedAlert();
  }

  function onHide() {
    setChanged(false);
    setSelectedTrain(null);
  }

  const sendTcm = (train: Train) => {
    confirmAlert(async () => {
      const result = await TrainService.sendTcm(await getTokenSilently!(), Number(train.id));
      if (result.data) {
        let data = result.data;
        if (data.response.status === 202) {
          errorAlert(
            "TCM received but not (yet) sent to the infrastructure manager. Contact the system administrator, you don't need to take further actions.",
          );
        } else {
          const updatedList = trains.data.map((item) => {
            if (item.id === data.id) {
              return data;
            } else {
              return item;
            }
          });
          setTrains({ ...trains, data: updatedList });
          succeedAlert();
        }
      } else {
        errorAlert(result?.message);
      }
    });
  };

  if (trains.error) {
    return (
      <div className={"d-flex justify-content-center align-items-center w-100"}>{trains.error}</div>
    );
  }

  return (
    <PageTransition>
      <Box className={"h-100 w-100"}>
        <TitleBar
          title={"Trains"}
          buttonText={"Add Train"}
          buttonMethod={() => {
            setChanged(true);
            setSelectedTrain(null);
          }}
          permissions={["write:train"]}
        />
        <TrainTable
          isFetching={trains.isFetching}
          onEditTrain={(train) => {
            setChanged(true);
            setSelectedTrain(train);
          }}
          onDeleteTrain={(train) => onDeleteTrain(train)}
          trains={trains.data}
          sendTcm={sendTcm}
        />
        {isChanged && (
          <ManageTrain
            getToken={() => getTokenSilently!()}
            onHide={onHide}
            onDataSent={showSuccess}
            onSave={onSave}
            onUpdate={onUpdate}
            trainDTO={selectedTrain}
          />
        )}
      </Box>
    </PageTransition>
  );
}
