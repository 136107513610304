import React from "react";
import { TRAIN_TYPES } from "../../../../utils/constants";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import DataTable, { TableColumn , Alignment } from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { hasPermissions } from "../../../../utils/scopeChecker";
import { Stack } from "@mui/material";
import { Filter, Spinner } from "../../../../components";
import  {formatDate } from "../../../../utils/formateDate";

export interface Train  {
  id: number;
  operationalTrainNumber: string;
  trainType: string;
  transferPoint: string;
  scheduledTimeAtHandover: string;
  customMessageStatuses: Array<{
    acknowledged: boolean;
  }>;
  scheduledDateTimeAtTransfer: string;
}

interface Props {
  trains: Train[];
  onEditTrain: (train: Train) => void;
  onDeleteTrain: (train: Train) => void;
  sendTcm: (train: Train) => void;
  isFetching: boolean;
}

export default function TrainTable({
  trains,
  onEditTrain,
  onDeleteTrain,
  sendTcm,
  isFetching,
}: Props) {
  const getStatusColor = (train: Train): string => {
    if (train && train.customMessageStatuses?.length) {
      return train.customMessageStatuses[train.customMessageStatuses.length - 1]
        .acknowledged
        ? "outline-success"
        : "outline-danger";
    }
    return "outline-warning";
  };

  const columns: TableColumn<Train>[] = [
    {
      name: "Number",
      selector: (row : any) => row.operationalTrainNumber,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row : any) => row.trainType,
      format: (row : any) => `${TRAIN_TYPES[row.trainType].summary}`,
      sortable: true,
    },
    {
      name: "Departure",
      selector: (row : any) => row.transferPoint,
      sortable: true,
    },
    {
      name: "Departure time",
      selector: (row : any) => row.scheduledTimeAtHandover,
      format: (row : any) => `${formatDate(row.scheduledTimeAtHandover)}`,
      sortable: true,
    },
  ];

  const getColumns = (): TableColumn<Train>[] => {
    const buttonsColumn = {
      cell: (row: Train) => (
        <Stack width={"100%"} spacing={1} direction={"row"}>
          <Button
            className={"text-nowrap"}
            variant={getStatusColor(row)}
            size="sm"
            onClick={() => sendTcm(row)}
          >
            TCM
          </Button>
          <Button
            className={"text-nowrap"}
            variant="outline-danger"
            size="sm"
            onClick={() => onDeleteTrain(row)}
          >
            Delete
          </Button>
          <Button
            className={"text-nowrap"}
            variant="outline-secondary"
            size="sm"
            onClick={
              hasPermissions(["write:train"])
                ? () => onEditTrain(row)
                : () => navigate(`../train/${row.id}/details`)
            }
          >
            {hasPermissions(["write:train"]) ? "EDIT" : "VIEW"}
          </Button>
        </Stack>
      ),
      ignoreRowClick: true,
      right: true,
      allowOverflow: true,
    };

    const editColumn = {
      cell: (row : any) => <Button
        className={"text-nowrap"}
        variant="outline-secondary"
        size="sm"
        onClick={hasPermissions(["write:train"])
          ? () => onEditTrain(row)
          : () => navigate(`../train/${row.id}/details`)}>
        {hasPermissions(["write:train"]) ? "EDIT" : "VIEW"}
      </Button>,
      ignoreRowClick: true,
      right: true,
    };
    if (hasPermissions(["send:traincompositionmessage"])) {
      return [...columns, buttonsColumn,];
    } else {
      return [...columns, editColumn];
    }
  };

  const navigate = useNavigate();
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredTrains = trains.filter(train => train.transferPoint && train.transferPoint.toLowerCase().includes(filterText.toLowerCase()));

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        placeholder={"Filter by Departure"}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={getColumns()}
      defaultSortFieldId="scheduledTimeAtHandover"
      data={filteredTrains}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      subHeaderAlign={Alignment.LEFT}
      persistTableHead
      highlightOnHover
      noHeader={true}
      pointerOnHover={true}
      onRowClicked={(row) => navigate(`../train/${row.id}/details`)}
      progressPending={isFetching}
      progressComponent={<Spinner/>}
    />
  );
}
