import React, { useState, useMemo } from "react";
import DataTable, { Alignment } from "react-data-table-component";
import Spinner from "../../../components/Spinner/Spinner";
import Filter from "../../../components/Filter/Filter";

interface Company {
  code: string;
  name: string;
  countryIso: string;
}

interface CompanyTableProps {
  companies: Company[];
  isFetching: boolean;
}

export default function CompanyTable({ companies, isFetching }: CompanyTableProps) {
  const columns = useMemo(
    () => [
      {
        name: "Code",
        selector: (row: Company) => row.code, //Nog geen waarde voor KvK in database
        sortable: true,
      },
      {
        name: "Name",
        selector: (row: Company) => row.name, //Nog geen waarde voor IBAN in database
        sortable: true,
      },
      {
        name: "Country",
        selector: (row: Company) => row.countryIso, // Deze waarde wordt nog niet gereturned
        sortable: true,
      },
    ],
    [],
  );

  const getColumns = () => {
    return columns;
  };

  const [filterText, setFilterText] = useState<string>("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState<boolean>(false);

  /**
   * Onderstaande filteredCompanies. Eerste filter op naam, tweede op code, derde werkt zowel op naam als code. Kan uitgebreid worden naar andere variabelen.
   */
  const filteredCompanies = useMemo(
    () =>
      companies.filter(
        (company: Company) =>
          (company.code && company.code.toLowerCase().includes(filterText.toLowerCase())) ||
          (company.name && company.name.toLowerCase().includes(filterText.toLowerCase())),
      ),
    [companies, filterText],
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
        onClear={handleClear}
        placeholder={"Filter by Code or Company"}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={getColumns()}
      defaultSortFieldId="scheduledTimeAtHandover"
      data={filteredCompanies}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      subHeaderAlign={Alignment.LEFT}
      persistTableHead
      highlightOnHover
      noHeader={true}
      pointerOnHover={true}
      progressPending={isFetching}
      progressComponent={<Spinner />}
    />
  );
}
