import React, { useEffect, useState } from "react";
import TrainService from "../../../../../api/trains";
import TrainActivitiesService from "../../../../../api/trainactivities";
import LocationsService from "../../../../../api/locations";
import { errorAlert, succeedAlert } from "../../../../../utils/Alerts";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";

interface Location {
  href: string;
  primaryLocationName: string;
  code: string;
  links: { rel: string }[];
}

interface LocationState {
  data: any[];
  isFetching: boolean;
  error: string;
}

interface TrainActivity {
  code?: string;
  description?: string;
  id?: number;
  links: { rel: string }[];
}

export interface Journey {
  id?: number;
  order?: number;
  links: {
    rel: string;
    title: string;
    href: string;
  }[];
  tractions: any[];
  wagons: any[];
  disabled: boolean;
  trainComposition?: {
    livestockOrPeopleIndicator: number;
  };
  activities?: any;
}

interface Props {
  onHide: () => void;
  selectedJourney: Journey;
  setJourneyAndTrainHandler: (data: any) => void;
  getToken: () => Promise<string>;
}

export default function EditJourney({
  onHide,
  selectedJourney,
  setJourneyAndTrainHandler,
  getToken,
}: Props) {
  const initialValidation = {
    destination: true,
    hasChanged: false,
  };

  const [validation, setValidation] = useState(initialValidation);
  useEffect(() => {
    setActivities(selectedJourney.activities!);

    const fetchLocationByHateoas = async (url: string, callback: (data: Location[]) => void) => {
      const { data, error } = await LocationsService.getLocationsByHateoas(url, await getToken());
      if (data) {
        callback(data);
      } else {
        errorAlert(error.message);
      }
    };

    let destination = selectedJourney.links.find(
      (l: { rel: string; href?: string }) => l.rel === "journeySectionDestination",
    )?.href as any;
    let departure = selectedJourney.links.find(
      (l: { rel: string; href?: string }) => l.rel === "journeySectionOrigin",
    )?.href as any;

    if (process.env.NODE_ENV === "production") {
      destination = destination?.replace("http://", "https://");
      departure = departure?.replace("http://", "https://");
    }

    fetchLocationByHateoas(destination, (data: Location[]) => {
      setDestination(data);
    });

    fetchLocationByHateoas(departure, (data: Location[]) => {
      setDeparture(data);
    });

    setLifeStockChecked(selectedJourney.trainComposition!.livestockOrPeopleIndicator === 1);
  }, [selectedJourney, getToken]);

  // User input
  const [departure, setDeparture] = useState<Location[]>([]);
  const [destination, setDestination] = useState<Location[]>([]);
  const [activities, setActivities] = useState<{ links: { rel: string }[] }[]>([]);
  const [lifeStockChecked, setLifeStockChecked] = useState<boolean>(false);

  // Activities handling state
  const [activitiesList, setActivitiesList] = useState<TrainActivity[]>([]);
  const [fetchingActivitiesError, setFetchingActivitiesError] = useState<string>("");

  // Locations handling state
  const [locations, setLocations] = useState<LocationState>({
    data: [],
    isFetching: false,
    error: "",
  });
  const [fetchingLocationsError, setFetchingLocationsError] = useState<string>("");

  // New Journey fetch state
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchActivities = async () => {
      const { data, error } = await TrainActivitiesService.getActivities(await getToken());
      if (data) {
        setActivitiesList(data);
      } else {
        setFetchingActivitiesError(error);
      }
    };

    const fetchLocations = async () => {
      const { data, error } = await LocationsService.getLocations(await getToken());
      if (data) {
        setLocations(data);
      } else {
        setFetchingLocationsError(error);
      }
    };

    fetchLocations();
    fetchActivities();
  }, [getToken]);

  function getDestination() {
    const destinationDetails = destination.find((item) => {
      return item.links.find((link) => link.rel === "self");
    });
    return destinationDetails;
  }

  function getDeparture() {
    const departureDetails = departure.find((item) => {
      return item.links.find((link) => link.rel === "self");
    });
    return departureDetails;
  }

  const submitForm = async (event: React.MouseEvent) => {
    event.preventDefault();
    let activitiesIds: { trainActivityTypeUrl: string }[] = [];

    for (let activity of activities) {
      activitiesIds.push({
        trainActivityTypeUrl: activity.links.find((l) => l.rel === "self")?.rel || "",
      });
    }

    let body = {
      journeySectionOriginUrl: getDeparture()?.href || "",
      journeySectionDestinationUrl: getDestination()?.href || "",
      livestockOrPeopleIndicator: lifeStockChecked ? 1 : 0,
      activities: activitiesIds,
    };

    const api = selectedJourney.links.find((l) => l.rel === "self")?.href || "";
    const { data, error } = await TrainService.editJourney(api, body, await getToken());
    if (data) {
      setJourneyAndTrainHandler(data);
      succeedAlert();
      onHide();
    } else {
      setError(error.message);
      errorAlert(error.message);
    }
  };

  function validateForm() {
    if (validation.destination && validation.hasChanged && departure) {
      return false;
    }
    return true;
  }

  function validateDestination(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      destination: value && value.toString().length > 0,
      hasChanged: true,
    }));
  }

  function validateActivities(value: any) {
    setActivities(value);
    setValidation((prevState) => ({ ...prevState, hasChanged: true }));
  }

  return (
    <div>
      <Dialog open={true} onClose={onHide} aria-labelledby="form-dialog-title" className="dialog">
        <DialogTitle id="form-dialog-title">Edit Journey</DialogTitle>
        <DialogContent className={"dialog-content overflow-visible"}>
          {locations.isFetching && (
            <div className="circular-progress">
              <CircularProgress />
            </div>
          )}
          {!locations.isFetching && (
            <form>
              <Grid container spacing={3} className="form-grid">
                <Grid item xs={12}>
                  <Autocomplete
                    options={locations.data}
                    getOptionLabel={(location) =>
                      `${location.code} - ${location.primaryLocationName}`
                    }
                    className="input-field"
                    value={departure}
                    onChange={(event, value) => setDeparture([value as Location])}
                    isOptionEqualToValue={(option, value) =>
                      option.primaryLocationName === value.primaryLocationName
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Departure" variant="outlined" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={locations.data}
                    getOptionLabel={(location) =>
                      `${location.code} - ${location.primaryLocationName}`
                    }
                    className="input-field"
                    value={destination}
                    onChange={(event, value) => {
                      setDestination([value as Location]);
                      validateDestination(value);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.primaryLocationName === value.primaryLocationName
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Destination" variant="outlined" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    options={activitiesList.sort((a, b) => Number(a.code) - Number(b.code))}
                    getOptionLabel={(activity: TrainActivity) =>
                      `${activity.code} - ${activity.description}`
                    }
                    className="input-field"
                    value={activities}
                    filterSelectedOptions
                    multiple
                    onChange={(event, value) => {
                      validateActivities(value);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField {...params} label="Activities" variant="outlined" />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lifeStockChecked}
                        onChange={(event) => setLifeStockChecked(event.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        name="Lifestock"
                      />
                    }
                    label="Lifestock"
                  />
                </Grid>
              </Grid>
              {locations.error && <div>{locations.error}</div>}
              {error && <div>{error}</div>}
              {fetchingLocationsError && <div>{fetchingLocationsError}</div>}
              {fetchingActivitiesError && <div>{fetchingActivitiesError}</div>}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={validateForm()}
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Edit
          </Button>
          <Button onClick={onHide} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
