import React from "react";
import DataTable, { Alignment } from "react-data-table-component";
import BootstrapButton from "react-bootstrap/Button";
import { hasPermissions } from "../../../utils/scopeChecker";
import { Button, Stack } from "@mui/material";
import { Filter } from "../../../components";
import { User } from "../../../models/User";

interface UserListProps {
  user: User;
  users: User[];
  customerDTO: any;
  onDeleteUser: (arg: any) => void;
  onEditUser: (arg: any) => void;
  addUserHandler: (arg: any) => void;
}

export default function UserList({
  user,
  users,
  customerDTO,
  onDeleteUser,
  onEditUser,
  addUserHandler,
}: UserListProps) {
  const columns = [
    {
      name: "Firstname",
      selector: (row: any) => row.firstname,
      sortable: true,
    },
    {
      name: "Lastname",
      selector: (row: any) => row.lastname,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
  ];

  const getColumns = () => {
    if (hasPermissions(["write:user" && "update:user"])) {
      const buttonsColumn = {
        cell: (row: any) => (
          <Stack spacing={1} direction={"row"}>
            <BootstrapButton
              className={"text-nowrap"}
              variant="outline-danger"
              style={{ visibility: row.email === user ? "hidden" : "visible" }}
              size="sm"
              onClick={() => onDeleteUser(row)}
            >
              Delete
            </BootstrapButton>
            <BootstrapButton
              className={"text-nowrap"}
              variant="outline-secondary"
              style={{ visibility: row.email === user ? "hidden" : "visible" }}
              size="sm"
              onClick={() => onEditUser(row)}
            >
              Edit
            </BootstrapButton>
          </Stack>
        ),
        ignoreRowClick: true,
        right: true,
      };

      return [...columns, buttonsColumn];
    } else if (hasPermissions(["write:user"])) {
      const editColumn = {
        cell: (row: any) => (
          <Button
            className={"text-nowrap"}
            variant="outlined"
            size="small"
            style={{ visibility: row.email === user ? "hidden" : "visible" }}
            onClick={() => onEditUser(row)}
          >
            Edit
          </Button>
        ),
        ignoreRowClick: true,
        right: true,
      };

      return [...columns, editColumn];
    } else {
      return columns;
    }
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  const filteredUsers = users.filter(
    (user) =>
      (user.lastname && user.lastname.toLowerCase().includes(filterText.toLowerCase())) ||
      (user.firstname && user.firstname.toLowerCase().includes(filterText.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(filterText.toLowerCase())),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        placeholder={"Filter by User"}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <DataTable
        columns={getColumns()}
        defaultSortFieldId="scheduledTimeAtHandover"
        // data={users}
        data={filteredUsers}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        subHeaderAlign={Alignment.RIGHT}
        persistTableHead
        highlightOnHover
        noHeader={true}
        pointerOnHover={true}
      />
      <Button
        sx={{ alignSelf: "flex-start", overflow: "hidden" }}
        variant={"contained"}
        size={"large"}
        type={"submit"}
        onClick={() => addUserHandler(customerDTO)}
      >
        Add User
      </Button>
    </>
  );
}
