import React, { useEffect, useState } from "react";
import Composition from "../../composition/Composition";
import { confirmAlert, errorAlert, succeedAlert } from "../../../../../utils/Alerts";
import TrainService from "../../../../../api/trains";
import { Timeline } from "@mui/lab";
import { JourneySectionTimelineItem, TitleBar } from "../../../../../components";

interface Link {
  rel: string;
  title: string;
  href: string;
}

interface JourneySection {
  id: number;
  order: number;
  links: Link[];
  tractions: any[];
  wagons: any[];
  disabled: boolean;
}

interface Train {
  id: number;
  name: string;
  journeySections: JourneySection[];
  transferPoint: string;
  links: Link[];
}

interface Props {
  train: Train;
  setShowCreateWagon: (value: React.SetStateAction<boolean>) => void;
  setShowCreateJourney: (value: React.SetStateAction<boolean>) => void;
  setShowEditJourney: (value: React.SetStateAction<boolean>) => void;
  selectedJourneyHandler: (value: JourneySection) => void;
  setTrain: (value: React.SetStateAction<Train | undefined>) => void;
  setJourneyAndTrainHandler: (value: JourneySection) => void;
  showEditMode: (item: any, selectedJourney: any) => boolean;
  fetchTrain: () => Promise<void>;
  getToken: () => Promise<string>;
  createTractionHandler: (journey: JourneySection) => Promise<void>;
  createWagonHandler: (journey: JourneySection) => Promise<void>;
}

export default function JourneysPicker({
  train,
  setShowCreateWagon,
  setShowCreateJourney,
  setShowEditJourney,
  selectedJourneyHandler,
  setTrain,
  setJourneyAndTrainHandler,
  showEditMode,
  fetchTrain,
  getToken,
  createTractionHandler,
  createWagonHandler
}: Props) {
  const [trainWithSortedJourneys, setTrainWithSortedJourneys] = useState<Train>(train);
  const [copiedJourneySectionUrl, setCopiedJourneySectionUrl] = useState<string>("");

  useEffect(() => {
    let listOfJourneys = train?.journeySections;
    listOfJourneys.sort((a : any, b : any) => a.id - b.id);
    setTrainWithSortedJourneys((prevState :any) => {
      return { ...prevState, journeySections: listOfJourneys };
    });
  }, [train]);

  const onDeleteJourney = (selectedJourney: JourneySection) => {
    let listOfJourneys = train?.journeySections;
    listOfJourneys.sort((a : any, b : any) => a.id - b.id);
    confirmAlert(async () => {
      try {
        const result = await TrainService.deleteJourney(train.id, selectedJourney.id, await getToken());
        if (result.data) {
          setTrain(result.data);
          succeedAlert();
        } else {
          errorAlert(result?.message);
        }
      } catch (e : any) {
        errorAlert("Failed delete request "+ e.message);
      }
    });
  };

  const retrieveDestinationName = () => {
    const journeySections = train?.journeySections;
    return getDestinationName(journeySections[journeySections.length - 1]);
  };

  function getDepartureName(journey: JourneySection) {
    const departure = journey.links.find((link : any) => link.rel === "journeySectionOrigin");
    return departure!.title;
  }

  function getDestinationName(journey: JourneySection) {
    const destination = journey?.links?.find((link : any)=> link?.rel === "journeySectionDestination")?.title;
    return destination ?? train?.transferPoint;
  }

  return (
    <>
      <TitleBar
        sxDivider={{ mb: 3, mt: 1 }}
        title={"Journeys"}
        size={undefined}
        buttonText={"Add Destination"}
        buttonMethod={() => setShowCreateJourney(true)}
        permissions={["write:journeysection"]}
      />
      <div style={{ overflow: "auto" }} className="partcontainer">
        <Timeline>
          {
            trainWithSortedJourneys.journeySections.map((journey : any, index : any) => {
              return (
                <JourneySectionTimelineItem
                  accordionTitle={getDepartureName(journey)}
                  journey={journey}
                  onDeleteClick={onDeleteJourney}
                  onEditClick={(journey) => {
                    setJourneyAndTrainHandler(journey);
                    setShowEditJourney(true);
                  }}
                  onSummaryFocus={() => selectedJourneyHandler(journey)}
                  key={index}
                  disabled={false}
                >
                  <Composition
                    getToken={getToken}
                    train={train}
                    selectedJourney={journey}
                    createTractionHandler={(journey) => createTractionHandler(journey)}
                    createWagonHandler={(journey) => createWagonHandler(journey)}
                    setShowCreateWagon={setShowCreateWagon}
                    setTrain={setTrain}
                    setJourneyAndTrainHandler={setJourneyAndTrainHandler}
                    showEditMode={showEditMode}
                    fetchTrain={fetchTrain}
                    copiedJourneySectionUrl={copiedJourneySectionUrl}
                    setCopiedJourneySectionUrl={setCopiedJourneySectionUrl}
                  />
                </JourneySectionTimelineItem>
              );
            })
          }
          <JourneySectionTimelineItem 
            accordionTitle={retrieveDestinationName()} 
            disabled={true}/>
        </Timeline>
      </div>
    </>
  );
}
