import React, { useState } from "react";
import { Card, CardContent, Chip, lighten, Stack, SvgIcon, Typography } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { Driver, Wagon } from "../../../utils/icons/composition";
import iconNames from "../../../utils/icons/SVGIcons";
import { HighlightOffRounded, Straighten, WarningRounded } from "@mui/icons-material";
import { confirmAlert, errorAlert, succeedAlert } from "../../../utils/Alerts";
import { hasPermissions } from "../../../utils/scopeChecker";
import { useTheme } from "@mui/styles";
import { JourneyDetailedDangerIconsPopover } from "../";
import { Journey } from "../../../models/Journey";
import { Vehicle } from "../../../models/Vehicle";

const DEFAULT_ELEVATION = 1;
const DRAGGING_ELEVATION = 16;
const ICON_SIZE = { height: 20, width: 20 };

interface JourneyVehicleCardProps {
  index: number;
  item: Vehicle;
  selectedJourney: Journey;
  setTrainCompositionHandler: (trainCompositionId: number, id: number) => void;
  showEditMode: (item: Vehicle, selectedJourney: Journey) => boolean;
  getToken: () => void;
  trainCompositionId: number;
  iconNumber: number;
}

/**
 * A reusable card that shows what type of vehicle is in the composition, this can be a Train or a Wagon.
 *
 * If the Wagon has dangerous goods within, the card will display a generic warning symbol.
 *
 * @param {Number} index
 * @param {Object} item
 * @param {Object} selectedJourney
 * @param {Function<void>} setTrainCompositionHandler
 * @param {boolean} showEditMode
 * @param {Number} trainCompositionId
 * @param {Number} iconNumber
 * @returns {JSX.Element}
 * @constructor
 */
export default function JourneyVehicleCard({
  index,
  item,
  selectedJourney,
  setTrainCompositionHandler,
  showEditMode,
  trainCompositionId
}: JourneyVehicleCardProps): JSX.Element {
  const theme = useTheme<any>();
  const [anchorElement, setAnchorElement]: any[] = useState(null);

  const identifier = () => {
    const { wagon, traction } = item;
    return wagon?.numberFreight ?? traction?.locoNumber;
  };

  // There is a .hasDangerousGoods property on `item`, though that is always false.
  // Might be a bug in the backend.
  const hasDangerousGoods = () => (item?.dangerGoods?.length ?? 0) !== 0;

  const deleteItem = () => {
    confirmAlert(async () => {
      try {
        setTrainCompositionHandler(trainCompositionId, item.id);
        succeedAlert();
      } catch (e: any) {
        const errorMessage = e instanceof String ? e : e.message;
        errorAlert(errorMessage);
      }
    });
  };

  const handleMouseEnter = (e: any) => setAnchorElement(e.target);

  const handleClose = () => setAnchorElement(null);

  return (
    <Draggable draggableId={String(item.id)} index={index}>
      {(provided: any, snapshot: any) => (
        <div
          className="d-flex flex-column"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card
            className={"position-relative me-3"}
            elevation={snapshot.isDragging ? DRAGGING_ELEVATION : DEFAULT_ELEVATION}
          >
            <CardContent>
              {hasDangerousGoods() && (
                <>
                  <WarningRounded
                    className={"position-absolute top-0 start-0 m-1"}
                    color={"warning"}
                    onMouseEnter={handleMouseEnter}
                  />
                  <JourneyDetailedDangerIconsPopover
                    anchorElement={anchorElement}
                    dangerGoods={item?.dangerGoods}
                    show={!!anchorElement}
                    handleClose={handleClose}
                  />
                </>
              )}
              {
                item.driverIndication && (
                  <SvgIcon sx={{ height: 25, width: 25 }} className={"position-absolute top-0 start-0 m-1"}> 
                    {Driver()}
                  </SvgIcon>
                )
              }
              {hasPermissions(["delete:rollingstock"]) && (
                <HighlightOffRounded
                  className={"position-absolute top-0 end-0 m-1"}
                  color={"error"}
                  onClick={deleteItem}
                />
              )}
              <Stack
                direction={"column"}
                alignItems={"center"}
                onClick={() =>
                  hasPermissions(["write:rollingstock"]) && showEditMode(item, selectedJourney)
                }
              >
                <SvgIcon sx={{ height: 50, width: 50 }}>
                  {item.stockType === "traction"
                    ? iconNames["traction"][parseInt(item.traction!.code, 10)]()
                    : item.wagon && isNaN(parseInt(item.wagon.code, 10))
                      ? Wagon()
                      : iconNames["wagons"][parseInt(item.wagon!.code, 10)]()}
                </SvgIcon>
                <Typography>{identifier()}</Typography>
              </Stack>
            </CardContent>
            <Stack
              direction={"row"}
              spacing={1}
              sx={{ bgcolor: lighten(theme.palette.primary.dark, 0.9), p: 1 }}
            >
              <Chip
                size={"small"}
                icon={<SvgIcon sx={ICON_SIZE}>{iconNames["weight"]()}</SvgIcon>}
                label={`${item.totalWeight}T`}
              />
              <Chip 
                size={"small"} 
                icon={<Straighten sx={ICON_SIZE} />} 
                label={`${item.length}m`} 
              />
              {item.stockType === "wagon" && (
                <Chip
                  size={"small"}
                  icon={<SvgIcon sx={ICON_SIZE}>{iconNames["brakepercentage"]()}</SvgIcon>}
                  label={`${item.brakeType}`}
                />
              )}
            </Stack>
          </Card>
        </div>
      )}
    </Draggable>
  );
}
