import { useAuth0 } from "../../react-auth0-spa";
import { useEffect } from "react";

/**
 * Most of the pages in this project first do a getAll call of the corresponding thing (e.g. Train or Wagon).
 * This method wraps that logic to reduce duplicate logic found in all the different pages.
 *
 * This method assumes a couple things about the state method:
 *  - It assumes the user of this method has an `isFetching` property in the state object.
 *  - It assumes the user of this method has an `error` property in the state object.
 *
 * @param {Function<Promise>} serviceMethod
 * @param {Function} stateChangeMethod
 * @returns {Promise<void>}
 */
const useFetch = (serviceMethod: (token: string) => Promise<{ data: any; error?: any }>, stateChangeMethod: (prevState: any) => void): void => {
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    stateChangeMethod((prevState : any) => ({ ...prevState, isFetching: true }));

    (async () => {
      const token = (await getTokenSilently?.()) || "";
      const { data, error } = await serviceMethod(token);
      if (data) {
        stateChangeMethod((prevState : any) => ({ ...prevState, data, isFetching: false }));
      } else {
        stateChangeMethod((prevState : any) => ({ ...prevState, data, isFetching: false, error: error }));
      }
    })();
  }, []);
};

export default useFetch;
