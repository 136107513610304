import React from "react";
import { Box, Divider, Drawer, List, ListItem, Typography } from "@mui/material";
import logo from "./logo-1.png";
import { hasPermissions } from "../../../utils/scopeChecker";
import { useAuth0 } from "../../../react-auth0-spa";
import { PATH } from "../../../utils/constants";
import { SidebarNavigationLink } from "../index";
import { User } from "../../../models/User";

const DRAWER_WIDTH = 240;

interface SidebarDrawerProps {
  currentUser: User;
}

/**
 * The Drawer of the RailLinkSystems application, all links of the application are defined here.
 *
 * @param {Object} currentUser
 * @returns {JSX.Element}
 * @constructor
 */
export default function SidebarDrawer({ currentUser }: SidebarDrawerProps): JSX.Element {
  const { isAuthenticated, logout, user } = useAuth0();

  const logoutHandler = () => {
    localStorage.removeItem("scopes");
    if (logout) {
      logout({ returnTo: window.location.origin });
    }
  };

  const navLinks = [
    { path: PATH.TRAINS, text: "Trains", icon: "trainset", permissions: ["read:train"] },
    { path: PATH.TRACTIONS, text: "Tractions", icon: "traction", permissions: ["read:traction"] },
    { path: PATH.WAGONS, text: "Wagons", icon: "wagons", permissions: ["read:wagon"] },
    { path: PATH.USERS, text: "Users", icon: "users", permissions: ["read:superuser"] },
    { path: PATH.CUSTOMERS, text: "Customers", icon: "company", permissions: ["read:company"] },
    { path: PATH.COMPANIES, text: "Companies", icon: "company", permissions: ["read:company"] },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          bgcolor: "primary.main",
        },
      }}
      open
    >
      <Box sx={{ pt: 2, px: 2 }}>
        <img src={logo} alt="logo" className={"w-100"} />
        <Typography
          className={"pt-5 text-white pb-3 fw-bold"}
          lineHeight={1.1}
          variant={"subtitle1"}
        >
          {currentUser?.customer?.customername}
        </Typography>
      </Box>
      <Divider sx={{ bgcolor: "primary.light" }} />
      <List>
        {navLinks
          .filter((item) => hasPermissions(item.permissions))
          .map((item) => (
            <SidebarNavigationLink item={item} key={item.text} />
          ))}
      </List>
      <Box sx={{ mt: "auto" }}>
        {isAuthenticated && (
          <List>
            <ListItem sx={{ color: "primary.light" }}>
              <Box>
                <Typography variant={"caption"}>{user?.["https://any-namespace/roles"]}</Typography>
                <Typography variant={"subtitle2"}>{user?.email}</Typography>
              </Box>
            </ListItem>
            <SidebarNavigationLink
              item={{ text: "logout", icon: "logout", path: "/logout", onClick: logoutHandler }}
            />
          </List>
        )}
      </Box>
    </Drawer>
  );
}
