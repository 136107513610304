import React from "react";
import { Box, Paper, Tooltip, Typography } from "@mui/material";
import { DangerGoodsType } from "../../../models/DangerGoodsType";

type DangerClassInformation = {
  [key: string]: {
    description: string;
    color: string;
  };
};

const DANGER_CLASS_INFORMATION: DangerClassInformation = {
  "1": {
    description: "Explosive substances and articles",
    color: "orange",
  },
  "2": {
    description: "Gases (Flammable, Non-flammable, Toxic)",
    color: "red",
  },
  "3": {
    description: "Flammable liquids",
    color: "red",
  },
  "4.1": {
    description:
      "Flammable solids, self-reactive substances, polymerizing substances and solid desensitized explosives",
    color: "red",
  },
  "4.2": {
    description: "Substances liable to spontaneous combustion",
    color: "white",
  },
  "4.3": {
    description: "Substances which, in contact with water, emit flammable gases",
    color: "#009eda",
  },
  "5.1": {
    description: "Oxidizing substances",
    color: "yellow",
  },
  "5.2": {
    description: "Organic peroxides",
    color: "red",
  },
  "6.1": {
    description: "Toxic substances",
    color: "white",
  },
  "6.2": {
    description: "Infectious substances",
    color: "white",
  },
  "7": {
    description: "Radioactive material",
    color: "yellow",
  },
  "8": {
    description: "Corrosive substances",
    color: "white",
  },
  "9": {
    description: "Miscellaneous dangerous substances and articles",
    color: "white",
  },
};

interface JourneyDetailedDangerIconProps {
  dangerGoodsType: DangerGoodsType;
}

/**
 * A reusable — more usefull — danger icon that changes based on the classification that it is in.
 *
 * @param {Object} dangerGoodsType
 * @returns {JSX.Element}
 * @constructor
 */
export default function JourneyDetailedDangerIcon({
  dangerGoodsType,
}: JourneyDetailedDangerIconProps): JSX.Element {
  // Default to "1" when recieving incorrect values:
  const rid = dangerGoodsType?.ridClass.toString() ?? "1";
  if (!dangerGoodsType?.ridClass) {
    console.info("Could not resolve 'ridClass' from dangerGoodsType, defaulting to '1'");
    console.dir(dangerGoodsType);
  }

  const classification =
    rid.indexOf(".") > 0
      ? rid.split(".")[0] // e.g. "6.1" -> "6"
      : rid; // e.g. "6"
  const dangerClass = DANGER_CLASS_INFORMATION[rid];

  return (
    <Tooltip title={dangerClass.description}>
      <Box className={"position-relative d-flex justify-content-center"}>
        <Paper
          sx={{
            bgcolor: dangerClass.color,
            height: "25px",
            width: "25px",
            transform: "rotate(-45deg)",
          }}
        />
        <Typography className={"position-absolute fw-bold"} sx={{ bottom: "-2px" }} fontSize={8}>
          {classification}
        </Typography>
      </Box>
    </Tooltip>
  );
}
