import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import TrainCompositionService from "../../../api/traincomposition";
import { errorAlert, succeedAlert } from "../../../utils/Alerts";
import { hasPermissions } from "../../../utils/scopeChecker";
import { JourneyVehicleList } from "../";
import { Journey } from "../../../models/Journey";
import { Vehicle } from "../../../models/Vehicle";

interface JourneyVehicleDragDropContextProps {
  selectedJourney: Journey;
  setTrainCompositionHandler: (trainCompositionId: number, id: number) => void;
  showEditMode: (item: Vehicle, selectedJourney: Journey) => boolean;
  fetchTrain: () => void;
  getToken: () => Promise<string>;
}

/**
 * A reusable DragDropContext, this is the place (context) where
 * draggables can be dragged and droppables can be dropped.
 *
 * For more information, check the documentation of react-beautiful-dnd (DragDropContext) here:
 * https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/drag-drop-context.md
 *
 * @param {Object} selectedJourney
 * @param {Function<void>} setTrainCompositionHandler
 * @param {boolean} showEditMode
 * @param {Function<void>} fetchTrain
 * @param {Function<String>} getToken
 * @returns {JSX.Element}
 * @constructor
 */
export default function JourneyVehicleDragDropContext({
  selectedJourney,
  setTrainCompositionHandler,
  showEditMode,
  fetchTrain,
  getToken,
}: JourneyVehicleDragDropContextProps): JSX.Element {
  const [items, setItems] = useState<Vehicle[]>([]);

  useEffect(() => {
    setItems(selectedJourney.trainComposition.rollingStock);
  }, [selectedJourney, selectedJourney.trainComposition.rollingStock, setItems]);

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const [itemBeingMoved] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, itemBeingMoved);
    return list;
  };

  const onDragEnd = useCallback(
    async (result: any) => {
      if (!hasPermissions(["write:rollingstock"])) {
        return;
      }

      const destinationPosition = result?.destination?.index;
      const currentPosition = result?.source?.index;
      const draggedStock: any = items[currentPosition];
      if (destinationPosition === undefined || draggedStock === undefined) {
        return;
      }

      if (destinationPosition === currentPosition) {
        return;
      }

      const moveStock = async () => {
        const body = { position: destinationPosition };
        const { error } = await TrainCompositionService.moveStock(
          selectedJourney.trainComposition.id,
          draggedStock.id,
          body,
          await getToken(),
        );
        if (error) {
          fetchTrain();
          errorAlert(error);
        } else {
          succeedAlert();
        }
      };

      setItems(reorder(items, result.source.index, result.destination.index));

      await moveStock();
    },
    [items, selectedJourney.trainComposition.id, fetchTrain, getToken],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <JourneyVehicleList
        getToken={getToken}
        showEditMode={showEditMode}
        data={items}
        trainCompositionId={selectedJourney?.trainComposition?.id}
        setTrainCompositionHandler={setTrainCompositionHandler}
        selectedJourney={selectedJourney}
      />
    </DragDropContext>
  );
}
