import React from "react";
import { Box, Grid } from "@mui/material";
import { Statistic } from "../../../../../components";

interface Props {
  selectedJourney: {
    trainComposition: {
      brakeWeight: number;
      weight: number;
      numberOfVehicles: number;
      length: number;
      maxSpeed: number;
      maxAxleWeight: number;
      gaugedExceptional: boolean;
      containsDangerousGoods: boolean;
    };
  };
}

export default function Statistics({ selectedJourney }: Props) {
  const calculateBrakeWeight = (): number => {
    let brakeWeight = selectedJourney.trainComposition.brakeWeight;
    let weight = selectedJourney.trainComposition.weight;
    let percentage = (brakeWeight > 0 && weight > 0) ? brakeWeight / weight * 100 : 0;
    return parseInt(percentage.toString(), 10);
  };
  const addInformation = (newInformation: { description: string; icon: string; value: string }): void => {
    information[0].push(newInformation);
  };

  let information: Array<Array<{ description: string; icon: string; value: string }>> = [
    [
      { description: "Number of vehicles", icon: "wagons", value: selectedJourney.trainComposition.numberOfVehicles.toString() },
      { description: "Length of train in meters", icon: "length", value: selectedJourney.trainComposition.length + " m" },
      { description: "Weight in tonnes", icon: "weight", value: selectedJourney.trainComposition.weight + " t" },
      { description: "Maximum speed in km/h", icon: "speed", value: selectedJourney.trainComposition.maxSpeed + " km/h" },
      {
        description: "Maximum axel-weight of the train",
        icon: "axel",
        value: selectedJourney.trainComposition.maxAxleWeight + " t"
      },
      { description: "Brake percentage", icon: "brakepercentage", value: calculateBrakeWeight() + "%" },
    ]
  ];
  if (selectedJourney.trainComposition.gaugedExceptional) {
    addInformation({
      description: "Exceptional gauging used",
      icon: "gauge",
      value: ""
    });
  }

  if (selectedJourney.trainComposition.containsDangerousGoods) {
    addInformation({
      description: "Dangerous goods in composition",
      icon: "warning",
      value: ""
    });
  }

  return (
    <>
      {selectedJourney?.trainComposition ?
        <Box display="block">
          {information.map((row, index) => (
            <Grid container
              key={index}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="icon-table">
              {row.map((item, index) => (
                <Statistic key={index} description={item.description} value={item.value} iconName={item.icon}/>
              ))}
            </Grid>
          ))}
        </Box>
        :
        <Box display="flex" flexDirection="row" justifyContent="center" px={3} py={1}>Composition information
          unavailable.</Box>
      }
    </>
  );
}
