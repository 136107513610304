import React, { useCallback, useEffect, useState } from "react";
import { Innerbar, Train, Journey } from "./innerbar/Innerbar";
import { useNavigate, useParams } from "react-router-dom";
import AddJourney from "./innerbar/journeys/addjourney/AddJourney";
import TrainService from "../../api/trains";
import EditJourney from "./innerbar/journeys/editjourney/EditJourney";
import CreateTraction from "./innerbar/composition/createTraction/CreateTraction";
import CreateWagon from "./innerbar/composition/createWagon/CreateWagon";
import { useAuth0 } from "../../react-auth0-spa";
import { Box } from "@mui/material";
import { PageLoader } from "../../components";

interface JourneySection {
  id: number;
  trainComposition: {
    rollingStock: unknown[];
  };
}

interface CompositionManagerState {
  data: unknown;
  showWagonEditor: boolean;
  showTractionEditor: boolean;
}

export default function TrainDetails(): JSX.Element {
  // Fetch train state
  const [train, setTrain] = useState<Train | undefined>();
  const [fetchingTrain, setFetchingTrain] = useState<boolean>(false);

  /* state for create/edit journey  */
  const [showCreateJourney, setShowCreateJourney] = useState<boolean>(false);
  const [showEditJourney, setShowEditJourney] = useState<boolean>(false);
  const [selectedJourney, setSelectedJourney] = useState<Journey  | undefined>();

  /* State for adding traction/wagon */
  const [showCreateTraction, setShowCreateTraction] = useState<boolean>(false);
  const [showCreateWagon, setShowCreateWagon] = useState<boolean>(false);

  /* State for editing traction/wagon */
  const initialCompositionManagerState: CompositionManagerState = { data: undefined, showWagonEditor: false, showTractionEditor: false };
  const [compositionManager, setCompositionManager] = useState<CompositionManagerState>(initialCompositionManagerState);

  const { trainid } = useParams<{ trainid: string }>();
  const navigate = useNavigate();
  const { getTokenSilently } = useAuth0();

  const getTrain = useCallback(async () => {
    setFetchingTrain(true);
    const { data } = await TrainService.getTrain(Number(trainid), await getTokenSilently!());
    if (data) {
      setTrain(data);
    }

    setFetchingTrain(false);
  }, [navigate, trainid, getTokenSilently]);



  useEffect(() => {
    getTrain();
  }, [getTrain]);

  useEffect(() => {
    if (selectedJourney) {
      const newJourney = train?.journeySections.find(journey => journey.id === selectedJourney.id);
      if (newJourney !== selectedJourney) {
        setSelectedJourney(prevState => newJourney ?? prevState);
      }
    }
  }, [train, selectedJourney]);

  const closeAllSidebars = () => {
    setCompositionManager(initialCompositionManagerState);
    setShowCreateTraction(false);
    setShowCreateWagon(false);
    setShowCreateJourney(false);
    setShowEditJourney(false);
  };

  const showEditJourneyHandler = () => {
    closeAllSidebars();
    setShowEditJourney(true);
  };

  const setCreateJourneyHandler = () => {
    closeAllSidebars();
    setShowCreateJourney(true);
  };

  const showCreateTractionHandler = () => {
    closeAllSidebars();
    setShowCreateTraction(true);
  };

  const processCompositionManager = (updatedJourneySection : any) => {
    const newSize = updatedJourneySection.trainComposition.rollingStock.length;
    const prevSize = selectedJourney?.trainComposition?.rollingStock?.length || 0;

    if (newSize < prevSize) {
      setCompositionManager(initialCompositionManagerState);
    }
  };

  const selectedJourneyHandler = (selectedJourney : any) => {
    processCompositionManager(selectedJourney);
    setSelectedJourney(selectedJourney);
    setTrain((prevState: any) => {
      const newJourneySectionsList = prevState.journeySections.filter((item: any) => item.id !== selectedJourney.id);
      newJourneySectionsList.push(selectedJourney);
      newJourneySectionsList.sort((a: any, b: any) => a.id - b.id);
      return ({ ...prevState, journeySections: newJourneySectionsList });
    });
  };

  const showCompositionEditorHandler = (item: any, selectedJourney: any): boolean => {
    const { stockType } = item;
    closeAllSidebars();
    setSelectedJourney(selectedJourney);
    if (stockType === "traction") {
      setCompositionManager(prevState => ({
        ...prevState,
        data: item,
        showTractionEditor: true,
        showWagonEditor: false
      }));
      return true;
    } else if (stockType === "wagon") {
      setCompositionManager(prevState => ({
        ...prevState,
        data: item,
        showTractionEditor: false,
        showWagonEditor: true
      }));
      return true;
    }
    setCompositionManager(prevState => ({
      ...prevState,
      data: undefined,
      showTractionEditor: false,
      showWagonEditor: false
    }));
    return false;
  };

  function journeyUpdatedHandler(updatedJourney : any) {
    let updatedTrain = train;
    let index = updatedTrain!.journeySections.findIndex(journey => journey.id === updatedJourney.id);
    if (index >= 0) {
      updatedTrain!.journeySections[index] = updatedJourney;
      setTrain(updatedTrain);
      setSelectedJourney(updatedJourney);
    }
  }

  return (
    <PageLoader isLoading={fetchingTrain}>
      <Box className={"h-100 w-100"}>
        {!fetchingTrain && train &&
          <Innerbar
            getToken={() => getTokenSilently!() as any}
            train={train}
            selectedJourney={selectedJourney}
            setSelectedJourney={setSelectedJourney}
            setShowCreateJourney={setCreateJourneyHandler}
            setShowEditJourney={showEditJourneyHandler}
            setShowCreateTraction={showCreateTractionHandler}
            setShowCreateWagon={showCreateWagon}
            setTrain={setTrain}
            setJourneyAndTrainHandler={selectedJourneyHandler}
            showEditMode={showCompositionEditorHandler}
            fetchTrain={() => getTrain()}
            onTractionUpdated={journeyUpdatedHandler}
            onWagonUpdated={journeyUpdatedHandler}/>
        }
        {showCreateJourney &&
          <AddJourney
            getToken={() => getTokenSilently!()}
            train={train}
            onHide={() => setShowCreateJourney(false)}
            setTrain={setTrain}
          />
        }
        {showEditJourney && getTokenSilently && selectedJourney &&
          <EditJourney
            getToken={() => getTokenSilently()}
            selectedJourney={selectedJourney}
            onHide={() => setShowEditJourney(false)}
            setJourneyAndTrainHandler={selectedJourneyHandler}
          />
        }
        {showCreateTraction && getTokenSilently &&
          <CreateTraction
            getToken={() => getTokenSilently()}
            onHide={() => setShowCreateTraction(false)}
            selectedJourney={selectedJourney}
            setSelectedJourney={selectedJourneyHandler}
          />
        }
        {showCreateWagon && getTokenSilently &&
          <CreateWagon
            getToken={() => getTokenSilently()}
            onHide={() => setShowCreateWagon(false)}
            selectedJourney={selectedJourney}
            setSelectedJourney={selectedJourneyHandler}
          />
        }
        {compositionManager.showWagonEditor && getTokenSilently &&
          <CreateWagon
            getToken={() => getTokenSilently()}
            onHide={() => setCompositionManager(prevState => ({ ...prevState, showWagonEditor: false, data: undefined }))}
            selectedJourney={selectedJourney}
            setSelectedJourney={selectedJourneyHandler}
            editData={compositionManager.data}
          />
        }
        {compositionManager.showTractionEditor && getTokenSilently &&
          <CreateTraction
            getToken={() => getTokenSilently()}
            onHide={() => setCompositionManager(prevState => ({
              ...prevState,
              showTractionEditor: false,
              data: undefined
            }))}
            selectedJourney={selectedJourney}
            setSelectedJourney={selectedJourneyHandler}
            editData={compositionManager.data}
          />
        }
      </Box>
    </PageLoader>
  );
}
