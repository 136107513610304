import React, { useState } from "react";
import TractionService from "../../api/tractions";
import TractionTable from "./table/TractionTable";
import ManageTraction from "./manageTraction/ManageTraction";
import { useAuth0 } from "../../react-auth0-spa";
import { confirmAlert, errorAlert, succeedAlert } from "../../utils/Alerts";
import { Box } from "@mui/material";
import { useFetch } from "../../effects";
import { PageTransition, TitleBar } from "../../components";
// eslint-disable-next-line no-unused-vars
import { Traction } from "../../models/Traction";

export default function TrainPicker() {
  const [tractions, setTractions] = useState<{ data: any[]; isFetching?: boolean; error?: string }>(
    { data: [], isFetching: false, error: "" },
  );
  const { getTokenSilently } = useAuth0();

  const initSidebar = {
    showManageTraction: false,
    showTractionTable: true,
    showCreateTraction: false,
    data: undefined,
  };
  const [sidebar, setSidebar] = useState(initSidebar);

  useFetch(TractionService.getTractions, setTractions);

  const closeAllSidebars = () => {
    setSidebar(initSidebar);
  };

  const editTractionHandler = (traction: Traction) => {
    closeAllSidebars();
    if (traction) {
      setSidebar((prevState: any) => ({ ...prevState, data: traction, showManageTraction: true }));
    }
  };

  const onDeleteTraction = (traction: Traction) => {
    confirmAlert(async () => {
      if (getTokenSilently) {
        try {
          const result = await TractionService.deleteTraction(
            traction.id,
            await getTokenSilently(),
          );

          if (result.data.response.status === 200) {
            let updatedList = tractions.data.filter((u: any) => u.id !== traction.id);
            setTractions({ data: updatedList });
            succeedAlert();
          } else {
            errorAlert(result.error.message);
          }
        } catch (e: any) {
          errorAlert("Failed delete request " + e.message);
        }
      }
    });
  };

  const createTractionHandler = () => {
    closeAllSidebars();
    setSidebar((prevState) => ({ ...prevState, showManageTraction: true }));
  };

  if (tractions.error) {
    return (
      <div className={"d-flex justify-content-center align-items-center w-100"}>
        Failed to fetch tractions: {tractions.error}
      </div>
    );
  }

  return (
    getTokenSilently && (
      <PageTransition>
        <Box className={"h-100 w-100"}>
          <TitleBar
            title={"Tractions"}
            size={undefined}
            buttonMethod={createTractionHandler}
            buttonText={"Add Traction"}
            permissions={["write:traction"]}
          />
          <TractionTable
            onEditTraction={(row) => editTractionHandler(row)}
            onDeleteTraction={(row) => onDeleteTraction(row)}
            tractions={tractions.data}
            isFetching={tractions.isFetching ? tractions.isFetching : false}
          />
          {sidebar.showManageTraction && (
            <ManageTraction
              getToken={async () => await getTokenSilently()}
              onHide={closeAllSidebars}
              onSave={setTractions}
              tractionDTO={sidebar.data}
            />
          )}
        </Box>
      </PageTransition>
    )
  );
}
