import React, { useEffect, useState } from "react";
import TrainService from "../../../../../api/trains";
import TrainActivitiesService from "../../../../../api/trainactivities";
import LocationsService from "../../../../../api/locations";
import { errorAlert, succeedAlert } from "../../../../../utils/Alerts";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";

interface Props {
  onHide: () => void;
  train: any;
  setTrain: (train: any) => void;
  getToken: () => Promise<string>;
}

interface LocationState {
  data: any[];
  isFetching: boolean;
  error: string;
}

export default function AddJourney({ onHide, train, setTrain, getToken }: Props) {
  // User input
  const [destination, setDestination] = useState<any>();
  const [activities, setActivities] = useState<any[]>([]);
  const [lifeStockChecked, setLifeStockChecked] = useState<boolean>(false);

  // Activities handling state
  const [activitiesList, setActivitiesList] = useState<any[]>([]);
  const [fetchingActivities, setFetchingActivities] = useState<boolean>(false);
  const [fetchingActivitiesError, setFetchingActivitiesError] = useState<string>("");

  // Locations handling state
  const [locations, setLocations] = useState<LocationState>({
    data: [],
    isFetching: false,
    error: "",
  });
  // const [fetchingLocations, setFetchingLocations] = useState<boolean>(false);
  const [fetchingLocations] = useState<boolean>(false);
  const [fetchingLocationsError, setFetchingLocationsError] = useState<string>("");

  // New Journey fetch state
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchLocations = async () => {
      setLocations((prev) => ({ ...prev, isFetching: true }));
      const { data, error } = await LocationsService.getLocations(await getToken());
      if (data) {
        setLocations({ data, isFetching: false, error: "" });
      } else {
        setFetchingLocationsError(error);
        setLocations((prev) => ({ ...prev, isFetching: false, error: "location is not avaible"}));
      }
    };
    fetchLocations();

    const fetchActivities = async () => {
      setFetchingActivities(true);
      const { data, error } = await TrainActivitiesService.getActivities(await getToken());
      if (data) {
        setActivitiesList(data);
      } else {
        setFetchingActivitiesError(error);
      }
      setFetchingActivities(false);
    };
    fetchActivities();
  }, [getToken]);

  function getDestination() {
    const destinationDetails = destination.links.find((item: any) => {
      return item.rel === "self";
    });
    return destinationDetails;
  }

  function getDeparture() {
    let departureDetails;
    if (train.journeySections.length === 0) {
      departureDetails = train.links.find((item: any) => {
        return item.rel === "transferPoint";
      });
    } else {
      const journeys = train.journeySections;
      const lastJourneySection = journeys[journeys.length - 1];
      departureDetails = lastJourneySection.links.find((item: any) => {
        return item.rel === "journeySectionDestination";
      });
    }
    return departureDetails;
  }

  const submitForm = async (event: React.MouseEvent) => {
    event.preventDefault();
    let activitiesIds: { trainActivityTypeUrl: string }[] = [];

    for (let activitie of activities) {
      activitiesIds.push({
        trainActivityTypeUrl: activitie.links.find((l: any) => l.rel === "self").href,
      });
    }

    let body = {
      journeySectionOriginUrl: getDeparture().href,
      journeySectionDestinationUrl: getDestination().href,
      livestockOrPeopleIndicator: lifeStockChecked ? 1 : 0,

      activities: activitiesIds,
    };

    const { data, error } = await TrainService.saveJourney(train.id, body, await getToken());
    if (data) {
      setTrain(data);
      succeedAlert();
      clearForm();
      onHide();
    } else {
      setError(error);
      errorAlert(error);
    }
  };

  function clearForm() {
    setDestination([]);
    setActivities([]);
    setLifeStockChecked(false);
  }

  function validateForm() {
    if (destination) {
      return false;
    }
    return true;
  }

  return (
    <div>
      <Dialog open={true} onClose={onHide} aria-labelledby="form-dialog-title" className="dialog">
        <DialogTitle id="form-dialog-title">Add Journey</DialogTitle>
        <DialogContent className={"dialog-content overflow-visible"}>
          {locations.isFetching && (
            <div className="circular-progress">
              <CircularProgress />
            </div>
          )}
          {!locations.isFetching && (
            <form>
              <Grid container spacing={3} className="form-grid">
                <Grid item xs={12}>
                  <TextField
                    label="Departure"
                    defaultValue={getDeparture().title}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    fullWidth
                    className="input-field"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    onChange={(event, newValue) => {
                      setDestination(newValue);
                    }}
                    // variant="outlined"
                    fullWidth
                    loading={fetchingLocations}
                    options={locations.data}
                    getOptionLabel={(option) => option.primaryLocationName}
                    isOptionEqualToValue={(option, value) =>
                      option.primaryLocationName === value.primaryLocationName
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {fetchingLocations ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={activitiesList.sort((a, b) => a.code - b.code)}
                    getOptionLabel={(option) => `${option.code} - ${option.description}`}
                    value={activities}
                    onChange={(event, value) => setActivities(value)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Activities"
                        placeholder="Choose activities..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {fetchingActivities ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    loading={fetchingActivities}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={lifeStockChecked}
                        onChange={(event) => setLifeStockChecked(event.target.checked)}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        name="Lifestock"
                      />
                    }
                    label="Lifestock"
                  />
                </Grid>
              </Grid>
              {locations.error && <div>{locations.error}</div>}
              {error && <div>{error}</div>}
              {fetchingLocationsError && <div>{fetchingLocationsError}</div>}
              {fetchingActivitiesError && <div>{fetchingActivitiesError}</div>}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={validateForm()}
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            Add
          </Button>
          <Button onClick={onHide} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
