import React, { useState } from "react";
import JourneySectionService from "../../../../../api/journeysections";
import { errorAlert, succeedAlert } from "../../../../../utils/Alerts";
import { Button } from "@mui/material";

type CloneCompositionProps = {
  selectedJourney: {
    trainComposition: {
      rollingStock?: any[];
    };
    links?: {
      rel: string;
      href: string;
    }[];
    id: string;
  };
  setTrain: (data: any) => void;
  getToken: () => Promise<string>;
  setCopiedJourneySectionUrl: (url: string) => void;
  copiedJourneySectionUrl: string;
};

type CloneResponse = {
  data?: any;
  error?: {
    message: string;
  };
};

export default function CloneComposition({
  selectedJourney,
  setTrain,
  getToken,
  setCopiedJourneySectionUrl,
  copiedJourneySectionUrl,
}: CloneCompositionProps) {
  const [copied, setCopied] = useState(false);

  const hasComposition = () => {
    const { trainComposition: { rollingStock } } = selectedJourney;
    return Boolean(rollingStock?.length);
  };

  const copiedJourneySectionUrlHandler = () => {
    setCopiedJourneySectionUrl(findSelectedJourneySelf());
    setCopied(true);
  };

  const isCloneable = () => {
    return Boolean(copiedJourneySectionUrl && !isCopied());
  };

  const isCopied = () => {
    return copied;
  };

  const findSelectedJourneySelf = () => {
    return selectedJourney?.links?.find((link) => link.rel === "self")?.href || "";
  };

  interface CloneResponse {
    data: any;
  }
  
  interface ErrorResponse {
    error: {
      message: string;
    };
  }
  
  const cloneComposition = async () => {
    try {
      const body = {
        journeySectionUrl: copiedJourneySectionUrl,
      };
      const response : any = await JourneySectionService.clone(
        selectedJourney.id,
        body,
        await getToken()
      );
      if (isErrorResponse(response)) {
        errorAlert(response.error.message);
      } else {
        setTrain(response.data);
        succeedAlert();
        setCopiedJourneySectionUrl("");
        setCopied(false);
      }
    } catch (e: any) {
      errorAlert(e.message);
    }
  };
  
  const isErrorResponse = (response: any): response is ErrorResponse => {
    return response?.error?.message != null;
  };

  return (
    <>
      {hasComposition() && (
        <Button
          disabled={isCopied()}
          className={"me-2 text-nowrap"}
          size="small"
          variant="outlined"
          onClick={copiedJourneySectionUrlHandler}
        >
          {isCopied() ? "COPIED!" : "COPY COMPOSITION"}
        </Button>
      )}
      {isCloneable() && (
        <Button
          className={"me-2"}
          variant="outlined"
          size="small"
          onClick={cloneComposition}
        >
          PASTE COMPOSITION
        </Button>
      )}
    </>
  );
}
