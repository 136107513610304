import React, { useState, useMemo } from "react";
// eslint-disable-next-line no-unused-vars
import DataTable, { Alignment, TableColumn } from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { hasPermissions } from "../../../utils/scopeChecker";
import { Stack } from "@mui/material";
import { Filter, Spinner } from "../../../components";

interface Props {
  users: User[];
  onDeleteUser: (user: User) => void;
  onEditUser: (user: User) => void;
  user: string;
  isFetching: boolean;
}

interface User {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
}

export default function CompanyTable({ users, onDeleteUser, onEditUser, user, isFetching }: Props) {
  const columns: TableColumn<User>[] = [
    {
      name: "Firstname",
      selector: (row: any) => row.firstname,
      sortable: true,
    },
    {
      name: "Lastname",
      selector: (row: any) => row.lastname,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: any) => row.role,
      sortable: true,
    },
  ];

  const getColumns = (): TableColumn<User>[] => {
    if (hasPermissions(["delete:user" && "update:user"])) {
      const buttonsColumn: TableColumn<User> = {
        cell: (row: any) => (
          <Stack spacing={1} direction={"row"}>
            <Button
              className={["text-nowrap"].join(" ")}
              variant="outline-danger"
              style={{
                visibility: row.email === user ? "hidden" : "visible",
              }}
              size="sm"
              onClick={() => onDeleteUser(row)}
            >
              Delete
            </Button>
            <Button
              className={["text-nowrap"].join(" ")}
              variant="outline-secondary"
              style={{
                visibility: row.email === user ? "hidden" : "visible",
              }}
              size="sm"
              onClick={() => onEditUser(row)}
            >
              Edit
            </Button>
          </Stack>
        ),
        ignoreRowClick: true,
        right: true,
      };

      return [...columns, buttonsColumn];
    } else if (hasPermissions(["update:user"])) {
      const editColumn: TableColumn<User> = {
        cell: (row: any) => (
          <Button
            className={["text-nowrap"].join(" ")}
            variant="outline-secondary"
            style={{
              visibility: row.email === user ? "hidden" : "visible",
            }}
            size="sm"
            onClick={() => onEditUser(row)}
          >
            Edit
          </Button>
        ),
        right: true,
        ignoreRowClick: true,
        button: true,
      };
      return [...columns, editColumn];
    } else {
      return columns;
    }
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredUsers = users.filter(
    (user) =>
      (user.lastname && user.lastname.toLowerCase().includes(filterText.toLowerCase())) ||
      (user.firstname && user.firstname.toLowerCase().includes(filterText.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(filterText.toLowerCase())) ||
      (user.role && user.role.toLowerCase().includes(filterText.toLowerCase())),
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        placeholder={"Filter by User"}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={getColumns()}
      defaultSortFieldId="scheduledTimeAtHandover"
      data={filteredUsers}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      subHeaderAlign={Alignment.LEFT}
      persistTableHead
      highlightOnHover
      noHeader={true}
      pointerOnHover={true}
      progressPending={isFetching}
      progressComponent={<Spinner />}
    />
  );
}
