import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { roles } from "../../utils/constants";
import UserTable from "./table/UserTable";
import UserService from "../../api/user";
import ManageUser from "./ManageUser/ManageUser";
import { confirmAlert, errorAlert, succeedAlert } from "../../utils/Alerts";
import TitleBar from "../../components/TitleBar/TitleBar";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useFetch } from "../../effects";
import { PageTransition } from "../../components";

interface UserDTO {
  userId?: number;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
}


export default function UserPicker() {
  const { user } = useAuth0();
  const [isChanged, setChanged] = useState<boolean>(false);

  const [users, setUsers] = useState<{ data: UserDTO[]; isFetching: boolean; error: string }>({ data: [], isFetching: false, error: ""});
  const { getTokenSilently } = useAuth0();

  const [users1, setUsers1] = useState<UserDTO | null>();

  const initSidebar = { showUserTable: true, showCreateUser: false, data: undefined };
  const [sidebar, setSidebar] = useState<{ showUserTable: boolean; showCreateUser: boolean; data?: UserDTO }>(initSidebar);
  const [rolelist, setRolelist] = useState(roles);

  useEffect(() => {
    if (sidebar.data) {
      const newObject = users.data.find(item => item.userId === sidebar.data!.userId);
      setSidebar(prevState => ({ ...prevState, data: newObject }));
    }
  }, [users, sidebar.data]);


  function onHide() {
    setChanged(false);
    setUsers1(null);
  }
  

  /**
   * rolelist genereren waarbij op basis van de rol van de ingelogde persoon een lijst word gemaakt
   * van de rollen die deze persoon kan aanmaken in een create/edit user formulier
   */
  const generateRolelist = () => {
    let filteredRoles = roles.filter((r) => (r.value >= (roles.find((r) => r.name === (user!["https://any-namespace/roles"][0]))!.value)));
    setRolelist(filteredRoles);
  };

  const onEditUser = (userDTO: UserDTO) => {
    generateRolelist();
    setChanged(true)
    setUsers1(userDTO);
    setSidebar(prevState => ({ ...prevState, showUserTable: true, showCreateUser: false, data: undefined }));

  };

  // const editUserHandler = () => {
  //   closeAllSidebars();
  //   setSidebar(prevState => ({ ...prevState, showUserTable: true, showCreateUser: false, data: undefined }));
  // };

  const onDeleteUser = async (userDTO: UserDTO) => {
    confirmAlert(async () => {
      const deleteBody = {
        "token": await getTokenSilently!(),
        "userid": userDTO.userId
      };
      try {
        const result = await UserService.delete(deleteBody, await getTokenSilently!());
        if (result.status === 202) {
          /**
           * update functie van lijst users zodat usertable update na delete actie
           */
          let updatedList = users.data.filter((u) => u.userId !== userDTO.userId);
          setUsers({ data: updatedList, isFetching: false, error: "" });
          succeedAlert();
        } else {
          errorAlert(result.error.message);
        }
      } catch (e : any) {
        errorAlert("Failed delete request " + e.message);
      }
    });
  };

  useFetch(UserService.getAll, setUsers);

  const closeAllSidebars = () => {
    setSidebar(initSidebar);
  };



  const addUserHandler = () => {
    generateRolelist();
    setChanged(true);
    setUsers1(null);
    setSidebar(prevState => ({ ...prevState, showUserTable: true, showCreateUser: true, data: undefined }));
  };

  const backToUserTable = () => {
    closeAllSidebars();
    setSidebar(prevState => ({ ...prevState, showUserTable: true, showCreateUser: false, data: undefined }));
  };

  if (users.error) {
    return (<div className={"d-flex justify-content-center align-items-center w-100"}>
      Failed to fetch users: {users.error}
    </div>);
  }

  return (
    <PageTransition>
      <Box sx={{ width: "100%", height: "100%" }}>
        {sidebar.showUserTable &&
          <TitleBar title={"Users"} buttonMethod={addUserHandler} buttonText={"Add User"} permissions={["write:user"]}/>
        }
        {sidebar.showUserTable &&
          <UserTable
            users={users.data}
            onEditUser={(row) => onEditUser(row)}
            onDeleteUser={(row) => onDeleteUser(row)}
            // userDTO={sidebar.data}
            user={user?.email}
            isFetching={users.isFetching}
          />
        }
        {isChanged && getTokenSilently &&
          <ManageUser
            rolelist={rolelist}
            getToken={() => getTokenSilently()}
            // backToUserTable={() => backToUserTable()}
            editUserHandler={() => onHide()}
            customerDTO={sidebar.data}
            mode="create"
            onSave={setUsers}
            userDTO={users1}
          />
        }
      </Box>
    </PageTransition>
  );
}
