import React, { useEffect, useState } from "react";
import { trainTypes } from "../../../utils/constants";
import TrainService from "../../../api/trains";
import { errorAlert } from "../../../utils/Alerts";
import LocationsService from "../../../api/locations";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Tooltip
} from "@mui/material";
import { formatDateToCustom } from "../../../utils/formateDate";

export interface TrainDTO {
  id: number;
  operationalTrainNumber: string;
  trainType: string;
  transferPoint: string;
  scheduledDateTimeAtTransfer: string;
  scheduledTimeAtHandover: any;
  customMessageStatuses: any;
}

interface Location {
  code: string;
  primaryLocationName: string;
  links: { href: string }[];
}

interface Locations {
  locations: Location[];
  isFetching: boolean;
  error: string;
}

export default function ManageTrain({
  getToken,
  onDataSent,
  onHide,
  onSave,
  onUpdate,
  trainDTO
}: {
  getToken: () => Promise<string>;
  onDataSent: () => void;
  onHide: () => void;
  onSave: (Train: TrainDTO) => void;
  onUpdate: (Train: TrainDTO) => void;
  trainDTO?: TrainDTO | null;
}) {
  const initForm = {
    trainNumber: "",
    trainType: "",
    departure: null as Location | null,
    departureTime: new Date().toISOString().substr(0, 16),
    isSubmitting: false,
    error: ""
  };
  const [form, setForm] = useState(initForm);
  const [locations, setLocation] = useState<Locations>({
    locations: [],
    isFetching: false,
    error: ""
  });
  const [hasChanged, setHasChanged] = useState(false);

  const initialValidation = {
    trainNumber: true,
    trainType: true
  };

  const [validation, setValidation] = useState(initialValidation);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data, error } = await LocationsService.getLocations(
        await getToken()
      );
      if (data) {
        setLocation(prevState => ({
          ...prevState,
          isFetching: false,
          locations: data
        }));

        // Set default or selected departure location
        if (trainDTO) {
          const selectedDeparture = data.find(
            (location : any) => location.primaryLocationName === trainDTO.transferPoint
          );
          const departureLocation: Location = {
            code: selectedDeparture!.code,
            primaryLocationName: selectedDeparture!.primaryLocationName,
            links: selectedDeparture!.links
          }
          setForm(prevState => ({ ...prevState, departure: departureLocation }));
        } else {
          // setForm(prevState => ({...prevState, departure: data[0]}));
        }
      } else {
        setLocation(prevState => ({
          ...prevState,
          isFetching: false,
          error
        }));
      }
    };
    fetchLocations();
  }, [getToken]);

  useEffect(() => {
    if (trainDTO) {
      const departureDatetime = new Date(
        trainDTO.scheduledDateTimeAtTransfer
      );
      setForm(prevState => ({
        ...prevState,
        trainNumber: trainDTO.operationalTrainNumber
      }));
      setForm(prevState => ({
        ...prevState,
        departureTime: departureDatetime.toISOString().substr(0, 16)
      }));
      setForm(prevState => ({ ...prevState, trainType: trainDTO.trainType }));
    }
  }, [trainDTO]);

  const submitForm = async () => {
    setForm(prevState => ({ ...prevState, isSubmitting: true }));

    let body = {
      transferPoint: form.departure!.links[0].href,
      operationalTrainNumber: form.trainNumber,
      scheduledDateTimeAtTransfer: Date.parse(form.departureTime),
      scheduledTimeAtHandover: Date.parse(form.departureTime),
      trainType: form.trainType
    };

    // Create or update train data
    const {
      data,
      error
    } = trainDTO ? await TrainService.editTrain(parseInt(trainDTO.id.toString(10)), body, await getToken()) : await TrainService.saveTrain(body, await getToken());
    if (data) {
      trainDTO ? onUpdate(data) : onSave(data);
      onDataSent();
    } else {
      errorAlert(error);
      setForm(prevState => ({ ...prevState, isSubmitting: false, error }));
    }
  };

  function validateForm() {
    return !(form.trainNumber && parseInt(form.trainNumber) > 0 && form.trainType && form.departure && form.departureTime && !form.isSubmitting && hasChanged);
  }

  function setTrainNumberHandler({ target }: { target: any }) {
    setForm(prevState => ({ ...prevState, trainNumber: target.value }));
    setHasChanged(true);
  }

  function setTrainTypeHandler({ target }: { target: any  }) {
    setForm(prevState => ({ ...prevState, trainType: target.value }));
    setHasChanged(true);
  }

  function setTrainDepartureHandler(newValue : any) {
    setForm(prevState => ({ ...prevState, departure: newValue }));
    setHasChanged(true);
  }

  function setTrainDepartureTimeHandler({ target }: { target: any }) {
    setForm(prevState => ({ ...prevState, departureTime: target.value }));
    setHasChanged(true);
  }

  function validateTrainType(value : any) {
    setValidation(prevState => ({ ...prevState, trainType: (value && value.toString().length > 0) }));
  }

  function validateTrainNumber(value : any) {
    setValidation(prevState => ({ ...prevState, trainNumber: (value && value >= 0 && value.toString().length <= 8) }));
  }
  return (
    <div>
      <Dialog open={true} onClose={(event, reason) =>{
        if(reason =='escapeKeyDown'){
        onHide()}}} aria-labelledby="form-dialog-title" className="dialog">
        <DialogTitle id="form-dialog-title">{trainDTO ? "Edit Train" : "Add Train"}</DialogTitle>
        <DialogContent className={"overflow-visible"}>
          {locations.isFetching &&
          <div className="circular-progress">
            <CircularProgress/>
          </div>}
          {!locations.isFetching &&
          <form>
            <Grid container spacing={3} className="form-grid">
              <Grid item xs={12} sm={6}>
                <Tooltip arrow title="Identifies the train for traffic management purposes by the Dispatcher, GSMR services, etc.">
                  <TextField
                    label="Train number"
                    type="number"
                    inputProps={{ min: 1, max: 99999999 }}
                    value={form.trainNumber}
                    error={!validation.trainNumber}
                    helperText={!validation.trainNumber ? "The trainnumber must be between 1-99999999" : ""}
                    onChange={(value) => {
                      setTrainNumberHandler(value);
                      validateTrainNumber(value.target.value);
                    }}
                    variant="outlined"
                    fullWidth
                    className="input-field"
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Choose a train type"
                  select
                  value={form.trainType}
                  error={!validation.trainType}
                  onChange={(value) => {
                    setTrainTypeHandler(value);
                    validateTrainType(value);
                  }}
                  variant="outlined"
                  className="input-field"
                  fullWidth
                >
                  {trainTypes.map(trainType => <MenuItem value={trainType.id}
                    key={trainType.id}>{trainType.name}</MenuItem>)}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="combo-box"
                  disableClearable
                  options={locations.locations}
                  getOptionLabel={(option => `${option.code} - ${option.primaryLocationName}`)}
                  value={form.departure || undefined}
                  loading={locations.isFetching}
                  onChange={(event, newValue) => setTrainDepartureHandler(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label= {(form.departure?.code && form.departure?.primaryLocationName) 
                        ? `${form.departure.code} - ${form.departure.primaryLocationName}`
                        : " "}
                      placeholder="Choose departure..." InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {locations.isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Departure time"
                type="datetime-local"
                value={form.departureTime}
                onChange={setTrainDepartureTimeHandler}
                InputLabelProps={{
                    shrink: true,
                }}
                fullWidth
            />
              </Grid>
            </Grid>
            {locations.error && <div>{locations.error}</div>}
            {form.error && <div>{form.error}</div>}
          </form>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={validateForm()} onClick={submitForm}>
            {trainDTO ? "EDIT" : "CREATE"} 
          </Button>
          <Button onClick={onHide} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
