export const PATH = {
  HOME: "/trains",
  TRAINS: "/trains",
  TRAINDETAILS: "/train/:trainid/details",
  TRACTIONS: "/tractions",
  WAGONS: "/wagons",
  COMPANIES: "/companies",
  USERS: "/users",
  CUSTOMERS: "/customers",
  ERROR: "/error",
};

export const trainTypes = [
  { id: "1", name: "Passenger" },
  { id: "2", name: "Freight" },
  { id: "3", name: "Light Engine" },
  { id: "4", name: "Engineering" }
];

export const ENDPOINTS = {
  TRAINS: `${process.env.REACT_APP_ENDPOINT}/api/v1/trains`,
  LOCATIONS: `${process.env.REACT_APP_ENDPOINT}/api/v1/locations`,
  TRACTIONS: `${process.env.REACT_APP_ENDPOINT}/api/v1/tractions`,
  JOURNEYSECTIONS: `${process.env.REACT_APP_ENDPOINT}/api/v1/journeysections`,
  TRAINACTIVITYTYPES: `${process.env.REACT_APP_ENDPOINT}/api/v1/trainactivitytypes`,
  DANGERLABELS: `${process.env.REACT_APP_ENDPOINT}/api/v1/dangerlabels`,
  WAGONS: `${process.env.REACT_APP_ENDPOINT}/api/v1/wagons`,
  TRACTIONMODES: `${process.env.REACT_APP_ENDPOINT}/api/v1/tractionmodes`,
  TRAINCOMPOSITIONS: `${process.env.REACT_APP_ENDPOINT}/api/v1/traincompositions`,
  DANGERGOODSTYPES: `${process.env.REACT_APP_ENDPOINT}/api/v1/dangergoodstypes`,
  COMPANIES: `${process.env.REACT_APP_ENDPOINT}/api/v1/companies`,
  OWNERS: `${process.env.REACT_APP_ENDPOINT}/api/v1/owners`,
  USERS: `${process.env.REACT_APP_ENDPOINT}/api/v1/users`,
  CUSTOMERS: `${process.env.REACT_APP_ENDPOINT}/api/v1/customers`
};

export const TRAIN_TYPES = [
  {
    "summary": "Other train type",
    "description": "0 Other Train types that are not covered with the four codes given above can be codified as \"other\" in the messages Passenger with Freight - military trains, the Overnight Express; Royalty, Head of States"
  },
  {
    "summary": "Passenger train",
    "description": "Passenger train Commercial train with passenger coaches or trainsets Empty run of Train with passenger coaches or trainsets Including Crew train (for Train Crew Members)"
  },
  {
    "summary": "Freight train",
    "description": "Freight train Train with freight wagons"
  },
  {
    "summary": "Light engine",
    "description": "Light engine (locomotive train) One or more engines without any carriages"
  },
  {
    "summary": "Engineering train",
    "description": "Engineering train Train for measurement, maintenance, instructions, homologation, etc"
  },
];

// * Identifies the type of a locomotive: First digit:
export const POWER_SUPPLY = [
  { key: 0, value: "Not specified" },
  {
    key: 1,
    value: "External electric power supply for traction (catenary and pantograph, third rail or other such as maglev"
  },
  {
    key: 2,
    value: "On-board traction power supply for traction without external electrical or other power supply available"
  },
  { key: 3, value: "Hybrid traction (both on-board or electric traction available" },
];

// * Second digit(definitions in chapter 2.2.2 of the LOC and PAS TSI 1302 / 2014):
export const TRACTION_UNIT = [
  { key: 0, value: "Not specified" },
  { key: 1, value: "Locomotive or power unit" },
  { key: 2, value: "Trainset or multiple unit or railcar" },
  { key: 3, value: "Shunter" },
  { key: 4, value: "On track machine or infrastructure inspection vehicle" },
];

export const CODE_OPTIONS_TRACTION = [
  { key: 0, value: "1", text: "Locomotive Standard" },
  { key: 1, value: "2", text: "Locomotive br189" },
  { key: 2, value: "3", text: "Locomotive 1300" },
  { key: 3, value: "4", text: "Locomotive 1600" },
  { key: 4, value: "5", text: "Locomotive 2300" },
  { key: 5, value: "6", text: "Locomotive 6400" },
];

export const CODE_OPTIONS_WAGON = [
  { key: 0, value: "1", text: "Wagon Standard" },
  { key: 1, value: "2", text: "Wagon Tank" },
  { key: 2, value: "3", text: "Wagon Hopper" },
  { key: 3, value: "4", text: "Wagon Container" },
  { key: 4, value: "5", text: "Wagon Coil" },
  { key: 5, value: "6", text: "Wagon Empty" },
  { key: 6, value: "7", text: "Wagon Vehicle" },
];

export const roles = [
  { id: "1", name: "Admin", value: 1 },
  { id: "2", name: "SuperUser", value: 2 },
  { id: "3", name: "User", value: 3 },
  { id: "4", name: "Developer", value: 0 }
];
