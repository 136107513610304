import React, { useEffect, useState } from "react";
import { errorAlert, succeedAlert } from "../../../utils/Alerts";
import WagonService from "../../../api/wagon";
import iconNames from "../../../utils/icons/SVGIcons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  MenuItem,
  SvgIcon,
  Typography,
} from "@mui/material";
import { CODE_OPTIONS_WAGON } from "../../../utils/constants";

interface Props {
  onHide: () => void;
  onSave: (prevState: any) => void;
  wagonDTO?: any;
  getToken: () => Promise<string>;
}

export default function ManageWagon({ onHide, onSave, wagonDTO, getToken }: Props) {
  const [isFetching, setFetching] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [code, setCode] = useState(wagonDTO?.code || 0);

  const initialForm = {
    brakeWeightG: null,
    brakeWeightP: null,
    code: 0,
    lengthOverBuffers: null,
    maxSpeed: null,
    numberFreight: null,
    numberOfAxles: null,
    typeName: null,
    weightEmpty: null,
  };
  const [form, setForm] = useState(initialForm);

  interface Validation {
    brakeWeightG: boolean;
    brakeWeightP: boolean;
    code: boolean;
    lengthOverBuffers: boolean;
    maxSpeed: boolean;
    numberFreight: boolean;
    numberOfAxles: boolean;
    typeName: boolean;
    weightEmpty: boolean;
  }

  const initialValidation: Validation = {
    brakeWeightG: true,
    brakeWeightP: true,
    code: true,
    lengthOverBuffers: true,
    maxSpeed: true,
    numberFreight: true,
    numberOfAxles: true,
    typeName: true,
    weightEmpty: true, 
  };
  const [validation, setValidation] = useState(initialValidation);

  useEffect(() => {
    if (wagonDTO) {
      setEditing(true);

      setForm({
        brakeWeightG: wagonDTO.brakeWeightG,
        brakeWeightP: wagonDTO.brakeWeightP,
        code: wagonDTO.code,
        lengthOverBuffers: wagonDTO.lengthOverBuffers,
        maxSpeed: wagonDTO.maxSpeed,
        numberFreight: wagonDTO.numberFreight,
        numberOfAxles: wagonDTO.wagonNumberOfAxles,
        typeName: wagonDTO.typeName,
        weightEmpty: wagonDTO.weightEmpty,
      });
    }
    else{
      setForm(prevState => ({ ...prevState, code: 0 }));
    }
  }, [wagonDTO]);

  const submitForm = async () => {
    setFetching(true);

    try {
      // The services don't throw if the HttpCode is different than 200
      // TODO: This should probably be changed
      const result = isEditing
        ? await WagonService.editWagon(wagonDTO.id, form, await getToken())
        : await WagonService.saveWagon(form, await getToken());
      if (!result.data) {
        // Not the cleanest solution, but we shouldn't change too much here (see comment above)
        throw Error(result.message);
      }

      if (isEditing) {
        onSave((prevState: any) => {
          const newList = prevState.data.map((item: any) => {
            if (item.id === result.data.id) {
              return result.data;
            } else {
              return item;
            }
          });
          return { ...prevState, data: newList };
        });
      } else {
        onSave((prevState: any) => ({ ...prevState, data: [...prevState.data, result.data] }));
      }
      succeedAlert();
    } catch (e: any) {
      errorAlert(e);
    }

    setFetching(false);
    onHide();
  };

  function isValidInput() {
    return (
      hasChanged &&
      !Object.values(validation).includes(false) &&
      !Object.values(form).includes(null)
    );
  }

  function validateBrakeWeightG(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      brakeWeightG: value && value >= 0 && value.toString().length <= 3,
    }));
    setHasChanged(true);
  }

  function validateBrakeWeightP(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      brakeWeightP: value && value >= 0 && value.toString().length <= 3,
    }));
    setHasChanged(true);
  }

  function validateCode(value: any) {
    setValidation((prevState) => ({ ...prevState, code: value && value.toString().length <= 20 }));
    setHasChanged(true);
  }

  function validateLengthOverBuffers(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      lengthOverBuffers: value && value >= 0 && value.toString().length <= 6,
    }));
    setHasChanged(true);
  }

  function validateMaxSpeed(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      maxSpeed: value && value >= 0 && value.toString().length <= 3,
    }));
    setHasChanged(true);
  }

  function validateNumberFreight(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      numberFreight: value && value.toString().length === 12,
    }));
    setHasChanged(true);
  }

  function validateNumberOfAxles(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      numberOfAxles: value && value >= 0 && value.toString().length <= 5,
    }));
    setHasChanged(true);
  }

  function validateTypeName(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      typeName: value && value.toString().length <= 12,
    }));
    setHasChanged(true);
  }

  function validateWeightEmpty(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      weightEmpty: value && value >= 0 && value.toString().length <= 6,
    }));
    setHasChanged(true);
  }

  const enterDelay = 2200;

  return (
    <div>
      <Dialog open={true} onClose={(event, reason) =>{
      if(reason =='escapeKeyDown'){
        onHide()}}} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">
                {isEditing ? "Edit Wagon" : "Add Wagon"}
              </Typography>
            </Grid>
            <Grid item>
              <SvgIcon fontSize="large">{code !== null && iconNames["wagons"][code]()}</SvgIcon>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={"overflow-visible"}>
          <Grid container spacing={3} className="form-grid">
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={enterDelay} arrow
                title={
                  "The name of the type of wagon. This name is used for the transporter to identify the wagon."
                }  enterNextDelay={enterDelay}
              >
                <TextField
                  required
                  autoFocus
                  variant="outlined"
                  error={!validation.typeName}
                  id="typeName"
                  label="Type Name"
                  type="text"
                  defaultValue={form.typeName}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateTypeName(e.target.value);
                  }}
                  fullWidth
                  helperText={
                    !validation.typeName ? "The type name can not be longer then 12 characters" : ""
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Icon"
                select
                value={form.code}
                onChange={(event) => {
                  const newCode = parseInt(event.target.value);
                  setCode(newCode);
                  setForm(prevState => ({ ...prevState, code: newCode }));
                  validateCode(newCode);
                }}                  
                variant="outlined"
                fullWidth
              >
                {CODE_OPTIONS_WAGON.map((cd) => (
                  <MenuItem value={cd.key} key={cd.key}>
                    {cd.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Tooltip enterDelay={enterDelay} arrow
                title={
                  "Identifies uniquely the (freight) wagon by its number. Identification code of a wagon based on the TSI OPE and CEN Recommendations and CIS wagons coded according to OSJD-UIC leaflet 402, which allows the conversion from 8 digits to 12 digits and viceversa."
                }  enterNextDelay={enterDelay}
              >
                <TextField
                  required
                  variant="outlined"
                  error={!validation.numberFreight}
                  id="numberFreight"
                  label="Number Freight"
                  type="number"
                  defaultValue={form.numberFreight}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateNumberFreight(e.target.value);
                  }}
                  helperText={
                    !validation.numberFreight ? "The freight number must be 12 characters long" : ""
                  }
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={enterDelay} enterNextDelay={enterDelay} arrow title="Weight of the Ground-operated handbrake.">
                <TextField
                  required
                  variant="outlined"
                  error={!validation.brakeWeightG}
                  id="brakeWeightG"
                  label="Brake Weight G (in tonnes)"
                  type="number"
                  defaultValue={form.brakeWeightG}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateBrakeWeightG(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0, max: 99999 } }}
                  helperText={
                    !validation.brakeWeightG ? "The brake weight must be between 1-9999" : ""
                  }
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={enterDelay} enterNextDelay={enterDelay} arrow title="Weight of the Platform-operated handbrake.">
                <TextField
                  required
                  variant="outlined"
                  error={!validation.brakeWeightP}
                  id="brakeWeightP"
                  label="Brake Weight P (in tonnes)"
                  type="number"
                  defaultValue={form.brakeWeightP}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateBrakeWeightP(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0, max: 99999 } }}
                  helperText={
                    !validation.brakeWeightP ? "The brake weight must be between 1-9999" : ""
                  }
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={enterDelay} enterNextDelay={enterDelay} arrow
                title={
                  "Maximum allowed speed of the wagon according to the load and entry in the Rolling Stock Databases. Expressed in km/h."
                }
              >
                <TextField
                  required
                  variant="outlined"
                  error={!validation.maxSpeed}
                  id="maxSpeed"
                  label="Max Speed (in km/h)"
                  type="number"
                  defaultValue={form.maxSpeed}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateMaxSpeed(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  helperText={!validation.maxSpeed ? "The max speed must be between 1-999 km/h" : ""}
                  fullWidth
                />
              </Tooltip>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={enterDelay} enterNextDelay={enterDelay} arrow
                title={
                  "The number of axles of the freight wagon."
                }
              >
                <TextField
                  required
                  variant="outlined"
                  error={!validation.numberOfAxles}
                  id="numberOfAxles"
                  label="Number Of Axles"
                  type="number"
                  defaultValue={form.numberOfAxles}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateNumberOfAxles(e.target.value);
                  }}
                  InputProps={{ inputProps: { min: 0, max: 99 } }}
                  helperText={
                    !validation.numberOfAxles ? "The number of axels must be between 1-99" : ""
                  }
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={enterDelay} arrow enterNextDelay={enterDelay}
                title={
                  "The weight of the empty freight wagon expressed in tonnes."
                }
              >
                <TextField
                  required
                  variant="outlined"
                  error={!validation.weightEmpty}
                  id="weightEmpty"
                  label="Weight Empty (in tonnes)"
                  type="number"
                  defaultValue={form.weightEmpty}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateWeightEmpty(e.target.value);
                  }}
                  fullWidth= {true}
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                  helperText={
                    !validation.weightEmpty ? "The weight in tonnes must be between 1-999" : ""
                  }
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={enterDelay} arrow enterNextDelay={enterDelay}
                title={
                  "Length over buffers expressed in meters. Buffers and chain couplers are the de facto UIC standard railway stock coupling used in the EU and UK, and on some surviving colonial railways, such as in South America and India on older coaches. These couplers are an assembly of several devices: buffers, hooks and links or screws."
                }
              >
                <TextField
                  required
                  variant="outlined"
                  error={!validation.lengthOverBuffers}
                  id="lengthOverBuffers"
                  label="Length Over Buffers (in meters)"
                  type="number"
                  defaultValue={form.lengthOverBuffers}
                  onChange={(e) => {
                    e.persist();
                    const { id, value } = e.target;
                    setForm(
                      (prevState) =>
                        ({
                          ...prevState,
                          [id]: value,
                        } as typeof prevState),
                    );
                    validateLengthOverBuffers(e.target.value);
                  }}
                  fullWidth
                  helperText={
                    !validation.lengthOverBuffers
                      ? "The length over buffers must be between 1-999"
                      : ""
                  }
                  InputProps={{ inputProps: { min: 0, max: 999 } }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={submitForm}
            disabled={isFetching || !isValidInput()}
            color="primary"
          >
            {wagonDTO ? "EDIT" : "CREATE"}
          </Button>
          <Button onClick={onHide} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
