import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useTheme } from "@mui/styles";

interface FilterProps {
  placeholder: string;
  onFilter: (arg: any) => void;
  onClear: () => void;
}

export default function Filter({ placeholder, onFilter }: FilterProps): JSX.Element {
  const theme = useTheme<any>();

  return (
    <TextField
      hiddenLabel
      placeholder={placeholder}
      variant="filled"
      size={"small"}
      onChange={onFilter}
      InputProps={{
        startAdornment: (
          <InputAdornment position={"start"}>
            <Search sx={{ color: "primary.main" }} />
          </InputAdornment>
        ),
        sx: {
          bgcolor: "rgba(202, 206, 231, 0.14)",
          typography: "subtitle2",
          "&:hover:not(.Mui-disabled)::before": {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          },
          "&::before": {
            borderBottom: `1px solid ${theme.palette.secondary.light}`,
          },
          input: {
            "&::placeholder": {
              color: "secondary.light",
              typography: "subtitle2",
            },
          },
        },
      }}
    />
  );
}
