import { ENDPOINTS } from "../utils/constants";
import FetchService from "./fetchservice";

class DangerGoodsTypesService extends FetchService {
  getAll(token: string): Promise<any> {
    return super
      .fetch(`${ENDPOINTS.DANGERGOODSTYPES}`, "GET", token)
      .then((data: any) => super.parseJSON(data))
      .catch((error: Error) => Promise.reject(new Error(error.message)));
  }
}

export default new DangerGoodsTypesService();
