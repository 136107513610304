import React from "react";
import DataTable, { Alignment } from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { hasPermissions } from "../../../utils/scopeChecker";
import { Stack } from "@mui/material";
import { Filter, Spinner } from "../../../components";
import { Traction } from "../../../models/Traction";

interface TractionTableProps {
  tractions: Traction[];
  onEditTraction: (traction: Traction) => void;
  onDeleteTraction: (traction: Traction) => void;
  isFetching: boolean;
}

export default function TractionTable({
  tractions,
  onEditTraction,
  onDeleteTraction,
  isFetching,
}: TractionTableProps) {
  const columns = [
    {
      name: "Number",
      selector: (row: Traction) => row.locoNumber,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row: Traction) => row.typeName,
      sortable: true,
    },
    {
      name: "Weight",
      selector: (row: Traction) => row.weight,
      sortable: true,
    },
  ];

  const getColumns = () => {
    if (hasPermissions(["write:traction"])) {
      const buttonsColumn = {
        cell: (row: Traction) => (
          <Stack spacing={1} direction={"row"}>
            <Button
              className={"text-nowrap"}
              variant="outline-danger"
              size="sm"
              onClick={() => onDeleteTraction(row)}
            >
              Delete
            </Button>
            <Button
              className={"text-nowrap"}
              variant="outline-secondary"
              size="sm"
              onClick={() => onEditTraction(row)}
            >
              Edit
            </Button>
          </Stack>
        ),
        ignoreRowClick: true,
        right: true,
      };

      return [...columns, buttonsColumn];
    } else {
      return columns;
    }
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredTractions = tractions.filter(
    (traction) =>
      traction.locoNumber && traction.locoNumber.toLowerCase().includes(filterText.toLowerCase()),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        placeholder={"Filter by Loco Number"}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={getColumns()}
      defaultSortFieldId="scheduledTimeAtHandover"
      data={filteredTractions}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      subHeaderAlign={Alignment.RIGHT}
      persistTableHead
      highlightOnHover
      noHeader={true}
      pointerOnHover={true}
      progressPending={isFetching}
      progressComponent={<Spinner />}
    />
  );
}
