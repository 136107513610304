import React, { useState } from "react";
import CompanyTable from "./table/CompanyTable";
import CompanyService from "../../api/company";
import TitleBar from "../../components/TitleBar/TitleBar";
import { Box } from "@mui/material";
import { useFetch } from "../../effects";
import  PageTransition  from "../../components/Page/PageTransition/PageTransition";

interface Company {
  code: string;
  name: string;
  countryIso: string;
}

interface CompanyState {
  data: Company[];
  isFetching: boolean;
  error: string;
}

export default function CompanyPicker() {
  const [companies, setCompanies] = useState<CompanyState>({ data: [], isFetching: false, error: "" });

  useFetch(CompanyService.getAll, setCompanies);

  if (companies.error) {
    return (<div className={"d-flex justify-content-center align-items-center w-100"}>
      Failed to fetch companies: {companies.error}
    </div>);
  }

  return (
    <PageTransition>
      <Box sx={{ width: "100%", height: "100%" }}>
        <TitleBar title={"Companies"}/>
        <CompanyTable companies={companies.data} isFetching={companies.isFetching}/>
      </Box>
    </PageTransition>
  );
}
