import React, { useEffect, useState } from "react";
import { errorAlert, succeedAlert } from "../../../utils/Alerts";
import CustomerService from "../../../api/customer";
import { Box, Button, LinearProgress, Stack, TextField } from "@mui/material";

interface ManageCustomerProps {
  onSave: (cb: (arg: any) => any) => void;
  customerDTO: any;
  getToken: () => Promise<string>;
  backToCustomerTable: () => void;
  addSuperUserHandler: (arg: any) => void;
}

export default function ManageCustomer({
  onSave,
  customerDTO,
  getToken,
  backToCustomerTable,
  addSuperUserHandler,
}: ManageCustomerProps) {
  const [isFetching, setFetching] = useState(false);
  // State gebruikt voor de form om onderscheid te maken tussen het creëren van een nieuwe customer of het aanpassen van een bestaande
  const [editMode, setEditMode] = useState(!!customerDTO);
  // Set de text van de submit button van de form
  const [title, setTitle] = useState("CREATE CUSTOMER");
  // States voor handelen van data van customer
  const [id] = useState(customerDTO ? customerDTO.id : "");
  const [customername, setCustomername] = useState(customerDTO ? customerDTO.customername : "");
  const [companyCode, setCompanyCode] = useState(customerDTO ? customerDTO.companyCode : "");
  const [iban, setIban] = useState(customerDTO ? customerDTO.iban : "");
  const [kvk, setKvk] = useState(customerDTO ? customerDTO.kvk : "");
  const [companyName, setCompanyname] = useState(customerDTO ? customerDTO.company.name : "");

  useEffect(() => {
    if (customerDTO) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [customerDTO]);

  useEffect(() => {
    if (customerDTO) {
      setTitle("EDIT CUSTOMER");
    } else {
      setTitle("CREATE AND ADD SUPERUSER");
    }
  }, [customerDTO]);

  const submitForm = async (event: any) => {
    event.preventDefault();
    setFetching(true);

    const body = {
      id: "",
      customername: customername,
      companyCode: companyCode,
      iban: iban,
      kvk: kvk,
    };

    const updateBody = {
      id: id,
      customername: customername,
      companyCode: companyCode,
      iban: iban,
      kvk: kvk,
    };

    const result = editMode
      ? await CustomerService.update(await getToken(), updateBody)
      : await CustomerService.saveCustomer(await getToken(), body);
    try {
      if (result.data) {
        if (customerDTO) {
          onSave((prevState: any) => {
            const newList = prevState.data.map((item: any) => {
              if (item.id === result.data.id) {
                return result.data;
              } else {
                return item;
              }
            });
            return { ...prevState, data: newList };
          });
        } else {
          onSave((prevState: any) => ({ ...prevState, data: [...prevState.data, result.data] }));
        }
        succeedAlert();
        setFetching(false);
        editMode ? backToCustomerTable() : addSuperUserHandler(result.data);
      } else {
        throw new Error(result.error.message);
      }
    } catch (e: any) {
      errorAlert(e.error.message);
    }

    setFetching(false);
  };

  return (
    <Box component={"form"} onSubmit={submitForm}>
      <Stack spacing={2}>
        <TextField
          label={"Customer Name"}
          fullWidth
          value={customername}
          onChange={(e) => setCustomername(e.target.value)}
          required
        />
        <TextField
          label={"Company Code"}
          fullWidth
          value={companyCode}
          onChange={(e) => setCompanyCode(e.target.value)}
          required
        />
        <TextField
          label={"Company Name"}
          disabled
          fullWidth
          value={companyName}
          onChange={(e) => setCompanyname(e.target.value)}
          required
        />
        <TextField
          label={"IBAN"}
          fullWidth
          value={iban}
          onChange={(e) => setIban(e.target.value)}
          required
        />
        <TextField
          label={"KvK"}
          fullWidth
          value={kvk}
          onChange={(e) => setKvk(e.target.value)}
          required
        />
        <Button
          sx={{ alignSelf: "flex-start", overflow: "hidden" }}
          variant={"contained"}
          size={"large"}
          type={"submit"}
          disabled={isFetching}
        >
          {title}
          {isFetching && (
            <LinearProgress
              sx={{ position: "absolute", width: "100%", height: "100%", opacity: 0.5 }}
            />
          )}
        </Button>
      </Stack>
    </Box>
  );
}
