import { ENDPOINTS } from "../utils/constants";
import FetchService from "./fetchservice";

class TractionService extends FetchService {
  getTractions(token: string) {
    return super
      .fetch(`${ENDPOINTS.TRACTIONS}`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  saveTraction(body: any, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRACTIONS}`, "POST", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error)));
  }

  editTraction(tractionId: string, body: any, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRACTIONS}/${tractionId}`, "PUT", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error)));
  }

  deleteTraction(tractionId: string, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRACTIONS}/${tractionId}`, "DELETE", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }
}

export default new TractionService();
