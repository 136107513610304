import React, { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import createAuth0Client, { Auth0Client, Auth0ClientOptions, RedirectLoginOptions } from "@auth0/auth0-spa-js";

type Auth0ContextType = {
  isAuthenticated?: boolean;
  user?: any;
  loading?: boolean;
  popupOpen?: boolean;
  loginWithPopup?: (params?: RedirectLoginOptions) => Promise<void>;
  handleRedirectCallback?: () => Promise<void>;
  getIdTokenClaims?: (...p: any) => Promise<any>;
  loginWithRedirect?: (...p: any) => Promise<void>;
  getTokenSilently?: (...p: any) => Promise<string>;
  getTokenWithPopup?: (...p: any) => Promise<string | undefined>;
  logout?: (...p: any) => void;
};

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<Auth0ContextType>({});
export const useAuth0 = () => useContext(Auth0Context);

type Auth0ProviderProps = Auth0ClientOptions & {
  children: React.ReactNode;
  onRedirectCallback?: (appState: any) => void;
};

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [user, setUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
  }, []);

  const loginWithPopup = async (params?: RedirectLoginOptions) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  if (!auth0Client) return null;

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => auth0Client.getTokenWithPopup(...p),
        logout: () => auth0Client.logout({ returnTo: window.location.origin }),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
