import React from "react";
import { Box, CircularProgress, Fade } from "@mui/material";

interface PageLoaderProps {
  isLoading: boolean;
  children: JSX.Element;
  noPadding?: boolean;
}

/**
 * A reusable component that will fade in once the parent component is done with loading,
 * this is controlled via the 'isLoading' boolean.
 *
 * If the loading takes more than 200ms, this components will show the CircularProgress component.
 *
 * @param {boolean} isLoading
 * @param {JSX.Element} children
 * @param {boolean} noPadding
 * @returns {JSX.Element}
 * @constructor
 */
export default function PageLoader({
  isLoading,
  children,
  noPadding = false,
}: PageLoaderProps): JSX.Element {
  return (
    <Box
      className={`w-100 h-100 d-flex justify-content-center align-items-center position-relative ${
        noPadding ? "" : "p-5"
      }`}
    >
      {isLoading && (
        <Fade
          in={isLoading}
          style={{ transitionDelay: isLoading ? "200ms" : "0ms" }}
          className={"position-absolute"}
        >
          <CircularProgress />
        </Fade>
      )}
      <Fade in={!isLoading}>{children}</Fade>
    </Box>
  );
}
