import React, { useState } from "react";
import WagonService from "../../api/wagon";
import WagonTable from "./table/WagonTable";
import ManageWagon from "./manageWagon/ManageWagon";
import { useAuth0 } from "../../react-auth0-spa";
import { confirmAlert, errorAlert, succeedAlert } from "../../utils/Alerts";
import { Box } from "@mui/material";
import { useFetch } from "../../effects";
import { PageTransition, TitleBar } from "../../components";

export default function WagonPicker(): JSX.Element {
  const [wagons, setWagons] = useState<{ data: any[]; isFetching: boolean; error: string }>({ data: [], isFetching: false, error: "" });
  const { getTokenSilently } = useAuth0();

  const initSidebar = {
    showEditWagon: false,
    showCreateWagon: false,
    showWagonTable: true,
    data: undefined
  };
  const [sidebar, setSidebar] = useState<typeof initSidebar>(initSidebar);

  useFetch(WagonService.getWagons, setWagons);

  const closeAllSidebars = () => {
    setSidebar(initSidebar);
  };

  const editWagonHandler = (wagon: any) => {
    closeAllSidebars();
    if (wagon) {
      setSidebar(prevState => ({ ...prevState, data: wagon, showCreateWagon: true }));
    }
  };

  const onDeleteWagon = (wagon: any) => {
    confirmAlert(async () => {
      try {
        const result = await WagonService.deleteWagon(wagon.id, await getTokenSilently!());

        if (result.data.response.status === 200) {
          let updatedList = wagons.data.filter((u: any) => u.id !== wagon.id);
          setWagons({ data: updatedList, isFetching: false, error: "" });
          succeedAlert();
        } else {
          errorAlert(result.error.message);
        }
      } catch (e : any) {
        errorAlert("Failed delete request " + e.message);
      }
    });
  };

  const createWagonHandler = () => {
    closeAllSidebars();
    setSidebar(prevState => ({ ...prevState, showCreateWagon: true }));
  };

  if (wagons.error) {
    return (<div className={"d-flex justify-content-center align-items-center w-100"}>
      Failed to fetch wagons: {wagons.error}
    </div>);
  }

  return (
    <PageTransition>
      <Box className={"h-100 w-100"}>
        <TitleBar title={"Wagons"} buttonMethod={createWagonHandler} buttonText={"Add Wagon"} permissions={["write:wagon"]}/>
        <WagonTable
          onEditWagon={editWagonHandler}
          onDeleteWagon={(row: any) => onDeleteWagon(row)}
          wagons={wagons.data}
          isFetching={wagons.isFetching}
        />
        {sidebar.showCreateWagon &&
          <ManageWagon
            getToken={() => getTokenSilently!()}
            onHide={closeAllSidebars}
            onSave={setWagons}
            wagonDTO={sidebar.data}
          />}
        {sidebar.showEditWagon &&
          <WagonTable
            onEditWagon={editWagonHandler}
            onDeleteWagon={(row: any) => onDeleteWagon(row)}
            isFetching={wagons.isFetching}
            wagons = {wagons.data}
          />}
      </Box>
    </PageTransition>
  );
}
