import React, { useEffect, useState } from "react";
import WagonService from "../../../../../api/wagon";
import TrainCompositionService from "../../../../../api/traincomposition";
import { errorAlert, succeedAlert } from "../../../../../utils/Alerts";
import DangerousGoods from "./DangerousGoods/DangerousGoods";
import JourneySectionService from "../../../../../api/journeysections";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import { TitleBar } from "../../../../../components";

interface Props {
  dangerGoods?: DangerGood[];
  add?: (dangerGood: DangerGood) => void;
  remove?: (index: number) => void;
  size?: number;
  onHide: () => void;
  selectedJourney: any;
  setSelectedJourney: (value: any) => void;
  getToken: () => Promise<string>;
  editData?: any;
}

interface Validation {
  wagon: boolean;
  loadWeight: boolean;
  hasChanged: boolean;
  breakType: boolean;
}

interface DangerGood {
  data: any;
  index: number;
  weight: string;
}

interface Form {
  wagon: any;
  loadWeight: number;
  breakType: string;
  dangerGoods: DangerGood[];
  isSubmitting: boolean;
  error: string;
}

export default function CreateWagon({
  onHide,
  selectedJourney,
  setSelectedJourney,
  getToken,
  editData,
}: Props) {
  const initialValidation: Validation = {
    wagon: true,
    loadWeight: true,
    breakType: true,
    hasChanged: false,
  };

  const [validation, setValidation] = useState(initialValidation);

  useEffect(() => {
    const fetchWagons = async () => {
      setWagons((prevState) => ({ ...prevState, isFetching: true }));
      const { data, error } = await WagonService.getWagons(await getToken());
      if (data) {
        setWagons((prevState) => ({
          ...prevState,
          isFetching: false,
          wagons: data,
        }));
      } else {
        setWagons((prevState) => ({ ...prevState, isFetching: false, error }));
      }
    };
    fetchWagons();
  }, [getToken]);

  useEffect(() => {
    if (editData) {
      setForm((prevState) => ({
        ...prevState,
        wagon: editData.wagon,
        loadWeight: editData.totalLoadWeight,
        breakType: editData.brakeType,
        dangerGoods: editData.dangerGoods.map((item: any, index: number) => ({
          data: item.dangerGoodsType,
          index,
          weight: item.dangerousGoodsWeight,
        })),
      }));
    }
  }, [editData]);

  const [wagons, setWagons] = useState<{
    wagons: any[];
    isFetching: boolean;
    error: string;
  }>({
    wagons: [],
    isFetching: false,
    error: "",
  });

  const [form, setForm] = useState<Form>({
    wagon: "",
    loadWeight: 0,
    breakType: "P",
    dangerGoods: [],
    isSubmitting: false,
    error: "",
  });

  const addDangerGoodHandler = (dangerGood: DangerGood) => {
    setForm((prevState) => ({
      ...prevState,
      dangerGoods: [...prevState.dangerGoods, dangerGood],
    }));
    setValidation((prevState) => ({ ...prevState, hasChanged: true }));
  };

  const removeDangerGoodHandler = (dangerGoodIndex: number) => {
    const filteredList = form.dangerGoods.filter((item) => item.index !== dangerGoodIndex);
    setForm((prevState) => ({ ...prevState, dangerGoods: filteredList }));
    setValidation((prevState) => ({ ...prevState, hasChanged: true }));
  };

  const setWagonHandler = (wagon: any) => {
    setForm((prevState) => ({ ...prevState, wagon }));
  };

  const setLoadWeightHandler = (loadWeight: number) => {
    setForm((prevState) => ({ ...prevState, loadWeight }));
  };

  const setBreakTypeHandler = (breakType: string) => {
    setForm((prevState) => ({ ...prevState, breakType }));
    setValidation((prevState) => ({ ...prevState, hasChanged: true }));
  };

  const validateForm = () => {
    return !(
      validation.hasChanged &&
      validation.wagon &&
      validation.loadWeight &&
      !form.isSubmitting
    );
  };

  const submitForm = async (event: React.MouseEvent) => {
    event.preventDefault();
    const numberFreight = form.wagon.numberFreight;

    if (!editData) {
      const containsStock = selectedJourney.trainComposition.rollingStock.some((stock: any) => {
        return stock?.wagon?.numberFreight === numberFreight;
      });
      if (containsStock) {
        return setForm((prevState) => ({
          ...prevState,
          error: "Stock exists in current composition",
        }));
      }
    }

    setForm((prevState) => ({ ...prevState, isSubmitting: true }));

    const trainCompositionId = selectedJourney.trainComposition.id;

    const dangerGoodsInWagonPostDtos = form.dangerGoods.map((item) => {
      return {
        dangerGoodsTypeUrl: item.data.links.find((link: any) => link.rel === "self").href,
        dangerousGoodsWeight: item.weight,
      };
    });

    const body = {
      stockType: "wagon",
      brakeType: form.breakType,
      totalLoadWeight: form.loadWeight,
      stockIdentifier: numberFreight,
      dangerGoodsInWagonPostDtos,
    };

    const { error } = editData
      ? await TrainCompositionService.updateStock(
        trainCompositionId,
        editData.id,
        body,
        await getToken(),
      )
      : await TrainCompositionService.saveStock(trainCompositionId, body, await getToken());

    if (error) {
      errorAlert(error);
    } else {
      const { data, error } = (await JourneySectionService.getJourneySectionById(
        selectedJourney.id,
        await getToken(),
      )) as { data: any; error: string };
      if (error) {
        setForm((prevState) => ({ ...prevState, error, isSubmitting: false }));
        errorAlert(error);
      } else {
        setSelectedJourney(data);
        setForm((prevState) => ({ ...prevState, isSubmitting: false }));
        succeedAlert();
        resetForm();
        onHide();
      }
    }
  };

  const resetForm = () => {
    setForm({
      wagon: "",
      loadWeight: 0,
      breakType: "P",
      dangerGoods: [],
      isSubmitting: false,
      error: "",
    });
  };

  function validateWagon(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      wagon: value && value.toString().length > 0,
      hasChanged: true,
    }));
  }

  function validateLoadWeight(value: any) {
    setValidation((prevState) => ({
      ...prevState,
      loadWeight: value && value >= 0 && value.toString().length <= 3,
      hasChanged: true,
    }));
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        onClose={onHide}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={"dialog-content overflow-visible"}>
          <TitleBar title={`${editData ? "Edit" : "Add"} Wagon`} />
          {wagons.isFetching && (
            <div className="circular-progress">
              <CircularProgress />
            </div>
          )}
          {!wagons.isFetching && (
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    options={wagons.wagons}
                    value={form.wagon || null}
                    onChange={(event, value) => {
                      setWagonHandler(value);
                      validateWagon(value);
                    }}
                    disabled={editData}
                    renderInput={(params) => (
                      <TextField {...params} label="Choose a Wagon" variant="outlined" />
                    )}
                    getOptionLabel={(wagon) => `${wagon.numberFreight} - ${wagon.code}`}
                    isOptionEqualToValue={(option, value) =>
                      option.numberFreight === value.numberFreight
                    }
                    fullWidth
                    loading={wagons.isFetching}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-loadweight"
                    label="Load weight (in tonnes)"
                    type="number"
                    error={!validation.loadWeight}
                    helperText={
                      !validation.loadWeight ? "The load weight must be between 1-999" : ""
                    }
                    InputProps={{ inputProps: { min: 0, max: 999 } }}
                    variant="outlined"
                    name="loadweight"
                    value={form.loadWeight}
                    onChange={(event) => {
                      setLoadWeightHandler(parseInt(event.target.value));
                      validateLoadWeight(event.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Tooltip
                    placement="bottom-start"
                    arrow
                    title="Ground-operated or Platform-operated handbrake"
                  >
                    <div>
                      <FormControlLabel
                        value="G"
                        control={
                          <Radio
                            checked={form.breakType === "G"}
                            onChange={() => setBreakTypeHandler("G")}
                            value="G"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "G" }}
                          />
                        }
                        label="G"
                      />
                      <FormControlLabel
                        value="P"
                        control={
                          <Radio
                            checked={form.breakType === "P"}
                            onChange={() => setBreakTypeHandler("P")}
                            value="P"
                            name="radio-button-demo"
                            inputProps={{ "aria-label": "P" }}
                          />
                        }
                        label="P"
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <DangerousGoods
                    getToken={() => getToken()}
                    dangerGoods={form.dangerGoods}
                    size={16}
                    add={addDangerGoodHandler}
                    remove={removeDangerGoodHandler}
                  />
                </Grid>
              </Grid>

              {/* {error && <div>{error}</div>} */}
              {/* TODO: might have to take a good look at this, see how we can improve this */}
              {form.error && <div className="mt-2 text-danger">{form.error}</div>}
              {wagons.error && <div className="mt-2 text-danger">{wagons.error}</div>}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={validateForm()}
            onClick={async (e) => {
              await submitForm(e);
            }}
          >
            {editData ? "Edit" : "Add"}
          </Button>
          <Button onClick={onHide} color="inherit">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
