import React from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";

interface TreeNode {
  id: number;
  name: string;
  status?: string;
  children?: TreeNode[];
}

interface TreeViewComponentProps {
  data: TreeNode[];
}

const renderTree = (nodes: TreeNode[]) => {
  return nodes.map((node) => (
    <TreeItem
      key={node.id}
      nodeId={node.id.toString()}
      label={node.name}
      icon={
        node.status === "acknowledged" ? <CheckIcon sx={{ color: "green" }} /> 
          : node.status === "error" ? <ErrorIcon sx={{ color: "red" }} /> 
            : node.status === "pending" ? <QueryBuilderIcon sx={{ color: "blue" }} /> 
              : null
      }    >
      {node.children && renderTree(node.children)}
    </TreeItem>
  ));
};

const TreeViewComponent: React.FC<TreeViewComponentProps> = ({ data }) => {
  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
    >
      {renderTree(data)}
    </TreeView>
  );
};

export default TreeViewComponent;
