import React from "react";
import { Box, SvgIcon, Tooltip, Typography } from "@mui/material";
import iconNames from "../../utils/icons/SVGIcons";
import { QuestionMarkRounded } from "@mui/icons-material";
import { Wagon } from "../../utils/icons/composition";

interface StatisticProps {
  description: string;
  value?: string | null;
  iconName: string;
  size?: any;
  color?: any;
}

const DEFAULT_SIZE = "large";
const DEFAULT_COLOR = "black";

/**
 * A reusable component that provides iconography and extra information when hovering over said icon.
 *
 * This component can optionally also display extra information next to the icon.
 *
 * @param {String} description
 * @param {Option<String>} value
 * @param {String} iconName
 * @param {String} size
 * @param {String} color
 * @returns {JSX.Element}
 * @constructor
 */
export default function Statistic({
  description,
  value = null,
  iconName,
  size = DEFAULT_SIZE,
  color = DEFAULT_COLOR,
}: StatisticProps): JSX.Element {
  const retrieveIcon = () => {
    if (iconName in iconNames) {
      const iconElement = iconNames[iconName as keyof typeof iconNames];
      let renderIcon;
      if (Array.isArray(iconElement)) {
        renderIcon = Wagon();
      } else if (typeof iconElement === "function") {
        renderIcon = iconElement();
      }
      return renderIcon;
    }

    // If the developer that uses this component gives the wrong value,
    // we return a question mark instead
    return <QuestionMarkRounded color={"error"} />;
  };

  return (
    <Box className={"d-flex align-items-center"}>
      <Tooltip arrow title={description}>
        <SvgIcon color={color} fontSize={size}>
          {retrieveIcon()}
        </SvgIcon>
      </Tooltip>
      {value !== null && <Typography sx={{ ml: 1 }}>{value}</Typography>}
    </Box>
  );
}
