import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


export function formatDate(input: string): string {
    const dateObj = dayjs(input);

    if (!dateObj.isValid()) {
        throw new Error('Invalid date format');
    }

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    return `${dateObj.date()} ${monthNames[dateObj.month()]} ${dateObj.year()} ${dateObj.format('HH:mm:ss')}`;
}


export const formatDateToCustom = (dateString: string): string => {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
}