import React, { useEffect, useState } from "react";
import { errorAlert, succeedAlert } from "../../../utils/Alerts";
import TractionService from "../../../api/tractions";
import { CODE_OPTIONS_TRACTION, POWER_SUPPLY, TRACTION_UNIT } from "../../../utils/constants";
import iconNames from "../../../utils/icons/SVGIcons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  SvgIcon,
} from "@mui/material";

interface ManageTractionProps {
  onHide: () => void;
  onSave: (cb: (arg: any) => any) => void;
  tractionDTO: any;
  getToken: () => Promise<string>;
}

export default function ManageTraction({
  onHide,
  onSave,
  tractionDTO,
  getToken,
}: ManageTractionProps) {
  const [brakeWeightG, setBrakeWeightG] = useState(tractionDTO?.brakeWeightG || "");
  const [brakeWeightP, setBrakeWeightP] = useState(tractionDTO?.brakeWeightP || "");
  const [code, setCode] = useState(tractionDTO?.code || 0);
  const [lengthOverBuffers, setLengthOverBuffers] = useState(tractionDTO?.lengthOverBuffers || "");
  const [locoNumber, setLocoNumber] = useState(tractionDTO?.locoNumber || "");
  const [locoTypeNumber, setLocoTypeNumber] = useState(tractionDTO?.locoTypeNumber || "");
  const [numberOfAxles, setNumberOfAxles] = useState(tractionDTO?.numberOfAxles || "");
  const [typeName, setTypeName] = useState(tractionDTO?.typeName || "");
  const [weight, setWeight] = useState(tractionDTO?.weight || "");
  const [maxSpeed, setMaxSpeed] = useState(tractionDTO?.maxSpeed || "");
  const [powerSupply, setPowerSupply] = useState(tractionDTO?.powerSupply || "");
  const [tractionUnit, setTractionUnit] = useState(tractionDTO?.tractionUnit || "");
  const [title, setTitle] = useState("CREATE");
  const [hasChanged, setHasChanged] = useState(false);

  const initialValidation = {
    brakeWeightG: true,
    brakeWeightP: true,
    code: true,
    lengthOverBuffers: true,
    locoNumber: true,
    locoTypeNumber: true,
    numberOfAxles: true,
    typeName: true,
    weight: true,
    tractionType: true,
    maxSpeed: true,
  };
  const [validation, setValidation] = useState(initialValidation);

  useEffect(() => {
    if (tractionDTO) {
      setTitle("Edit");
      const tractionType = tractionDTO.tractionType;
      if (tractionType?.code) {
        const charArray: string[] = Array.from(tractionType.code);
        if (charArray.length === 2) {
          setPowerSupply(POWER_SUPPLY[parseInt(charArray[0])].key);
          setTractionUnit(TRACTION_UNIT[parseInt(charArray[1])].key);
        }
      }
    } else {
      setTitle("Create");
      setPowerSupply(0);
      setTractionUnit(0);
      setCode(0);
    }
  }, [tractionDTO]);

  const initForm = {
    brakeWeightG: {
      error: "",
    },
    brakeWeightP: {
      error: "",
    },
    code: {
      error: "",
    },
    lengthOverBuffers: {
      error: "",
    },
    locoNumber: {
      error: "",
    },
    locoTypeNumber: {
      error: "",
    },
    numberOfAxles: {
      error: "",
    },
    tractionType: {
      error: "",
    },
    typeName: {
      error: "",
    },
    maxSpeed: {
      error: "",
    },
    weight: {
      error: "",
    },
  };
  const [form, setForm] = useState(initForm);

  const submitForm = async (event: any) => {
    event.preventDefault();
    setForm(initForm);

    const body = {
      brakeWeightG,
      brakeWeightP,
      code,
      lengthOverBuffers,
      locoNumber,
      locoTypeNumber,
      numberOfAxles,
      typeName,
      weight,
      maxSpeed,
      tractionType: `${powerSupply}${tractionUnit}`,
    };

    const result = tractionDTO
      ? await TractionService.editTraction(tractionDTO.id, body, await getToken())
      : await TractionService.saveTraction(body, await getToken());

    try {
      if (result.data) {
        if (tractionDTO) {
          onSave((prevState: any) => {
            const newList = prevState.data.map((item: any) => {
              if (item.id === result.data.id) {
                return result.data;
              } else {
                return item;
              }
            });
            return { ...prevState, data: newList };
          });
        } else {
          onSave((prevState) => ({ ...prevState, data: [...prevState.data, result.data] }));
        }
        succeedAlert();
        onHide();
      } else if (result?.error?.errors) {
        result.error.errors.forEach((element: any) => {
          const { field, message } = element;
          setForm((prevState) => {
            let updatedFieldName = form[field as keyof typeof form];
            updatedFieldName.error = message;
            return { ...prevState, field: updatedFieldName };
          });
        });
      } else {
        throw new Error(result.message);
      }
    } catch (e: any) {
      errorAlert(e);
    }
  };

  function validateForm() {
    return !(
      !Object.values(validation).includes(false) &&
      brakeWeightG &&
      brakeWeightP &&
      locoNumber &&
      locoTypeNumber &&
      numberOfAxles &&
      typeName &&
      weight &&
      maxSpeed &&
      powerSupply &&
      hasChanged
    );
  }

  function validateBrakeWeightG(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      brakeWeightG: value && value >= 0 && value.toString().length <= 5,
    }));
    setHasChanged(true);
  }

  function validateBrakeWeightP(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      brakeWeightP: value && value >= 0 && value.toString().length <= 5,
    }));
    setHasChanged(true);
  }

  function validateCode(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      code: value && value.toString().length <= 12,
    }));
    setHasChanged(true);
  }

  function validateLengthOverBuffers(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      lengthOverBuffers: value && value >= 0 && value.toString().length <= 6,
    }));
    setHasChanged(true);
  }

  function validateLocoNumber(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      locoNumber:
        value && value >= 0 && value.toString().length <= 12 && value.toString().length >= 4,
    }));
    setHasChanged(true);
  }

  function validateLocoTypeNumber(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      locoTypeNumber:
        value && value >= 0 && value.toString().length <= 12 && value.toString().length >= 5,
    }));
    setHasChanged(true);
  }

  function validateNumberOfAxles(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      numberOfAxles: value && value >= 0 && value.toString().length <= 4,
    }));
    setHasChanged(true);
  }

  function validateTypeName(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      typeName: value && value.toString().length <= 12,
    }));
    setHasChanged(true);
  }

  function validateMaxSpeed(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      weight: value && value >= 0 && value.toString().length <= 3,
    }));
    setHasChanged(true);
  }

  function validateWeightEmpty(value: number) {
    setValidation((prevState: any) => ({
      ...prevState,
      weight: value && value >= 0 && value.toString().length <= 6,
    }));
    setHasChanged(true);
  }

  return (
    <Dialog open={true} onClose={(event, reason) =>{
      if(reason =='escapeKeyDown'){
        onHide()}}}
        >
      <DialogTitle id="form-dialog-title">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {title} Traction
            </Typography>
          </Grid>
          <Grid item>
            <SvgIcon fontSize="large">{code !== null && iconNames["traction"][code]()}</SvgIcon>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={"overflow-visible"}>
        <form onSubmit={submitForm} className="form-wrapper">
          <Grid container spacing={3} className="form-grid">
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="The name of the type of locomotive. This name is used for the transporter to identify the locomotive.">
                <TextField
                  required
                  label="Type Name"
                  value={typeName}
                  onChange={(event) => {
                    setTypeName(event.target.value);
                    validateTypeName(parseInt(event.target.value));
                  }}
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Icon"
                select
                value={code} 
                onChange={(event) => {
                  const newCode = parseInt(event.target.value);
                  setCode(newCode);
                  validateCode(newCode);
                }}                  
                variant="outlined"
                fullWidth
              >
                {CODE_OPTIONS_TRACTION.map((cd) => (
                  <MenuItem value={cd.key} key={cd.key}>
                    {cd.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>  
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="Identify the type of traction unit.">
                <TextField
                  required
                  label="Traction Unit"
                  select
                  value={tractionUnit}
                  onChange={(event) => setTractionUnit(parseInt(event.target.value))}
                  variant="outlined"
                  fullWidth
                >
                  {TRACTION_UNIT.map((tu) => (
                    <MenuItem value={tu.key} key={tu.key}>
                      {tu.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="The type of power that is being supplied to the traction unit.">
                <TextField
                  required
                  label="Power Supply"
                  select
                  value={powerSupply}
                  onChange={(event) => setPowerSupply(parseInt(event.target.value))}
                  variant="outlined"
                  fullWidth
                >
                  {POWER_SUPPLY.map((ps) => (
                    <MenuItem value={ps.key} key={ps.key}>
                      {ps.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750}
                arrow
                title={
                  "Identifies the number of the locomotive, usually the European Vehicle Number on 12N." +
                  " It is currently not restricted only to numeric values."
                }
              >
                <TextField
                  required
                  label="Loco Number"
                  value={locoNumber}
                  error={!validation.locoNumber}
                  onChange={(event) => {
                    setLocoNumber(event.target.value);
                    validateLocoNumber(parseInt(event.target.value));
                  }}
                  helperText={
                    !validation.locoNumber
                      ? "The loco number must be between 4 and 12 characters long"
                      : ""
                  }
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750}
                arrow
                title={
                  "Identifies the design series of the locomotive type. " +
                  "The definition is made on a national basis by each IM. " +
                  "The first 4 characters will be the CompanyIdent of the Engine Operator; " +
                  "the next 8 characters are based on national/company rules."
                }
              >
                <TextField
                  required
                  label="Loco Type Number"
                  type="number"
                  value={locoTypeNumber}
                  error={!validation.locoTypeNumber}
                  onChange={(event) => {
                    setLocoTypeNumber(event.target.value);
                    validateLocoTypeNumber(parseInt(event.target.value));
                  }}
                  InputProps={{ inputProps: { min: 0, max: 999999999999 } }}
                  helperText={
                    !validation.locoTypeNumber
                      ? "The loco type number must be between 5 and 12 characters long"
                      : ""
                  }
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="The number of axles of the traction unit.">
                <TextField
                  required
                  label="Number of Axles"
                  type="number"
                  value={numberOfAxles}
                  error={!validation.numberOfAxles}
                  onChange={(event) => {
                    setNumberOfAxles(event.target.value);
                    validateNumberOfAxles(parseInt(event.target.value));
                  }}
                  InputProps={{ inputProps: { min: 0, max: 99 } }}
                  helperText={
                    !validation.lengthOverBuffers ? "The number of axels must be between 1-99" : ""
                  }
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow
                title={
                  "Length over buffers expressed in meters. " +
                  "Buffers and chain couplers are the de facto UIC standard railway stock coupling used in the EU and UK, " +
                  "and on some surviving colonial railways, such as in South America and India on older coaches. " +
                  "These couplers are an assembly of several devices: buffers, hooks and links or screws."
                }
              >
                <TextField
                  required
                  label="Length Over Buffers (in meters)"
                  type="number"
                  value={lengthOverBuffers}
                  error={!validation.lengthOverBuffers}
                  onChange={(event) => {
                    setLengthOverBuffers(event.target.value);
                    validateLengthOverBuffers(parseInt(event.target.value));
                  }}
                  helperText={
                    !validation.lengthOverBuffers
                      ? "The length over buffers must be between 1-999999 in meters"
                      : ""
                  }
                  InputProps={{ inputProps: { min: 0, max: 999999 } }}
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="Maximum allowed speed of the traction unit. Expressed in km/h.">
                <TextField
                  required
                  label="Max speed (km/h)"
                  type="number"
                  value={maxSpeed}
                  onChange={(event) => {
                    setMaxSpeed(parseInt(event.target.value));
                    validateMaxSpeed(parseInt(event.target.value));
                  }}
                  InputProps={{ inputProps: { min: 1 } }}
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="The weight of the traction unit expressed in tonnes.">
                <TextField
                  required
                  label="Weight (in tonnes)"
                  type="number"
                  value={weight}
                  error={!validation.weight}
                  onChange={(event) => {
                    setWeight(event.target.value);
                    validateWeightEmpty(parseInt(event.target.value));
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="Weight of the Ground-operated handbrake.">
                <TextField
                  required
                  label="Brake Weight G (in tonnes)"
                  type="number"
                  value={brakeWeightG}
                  error={!validation.brakeWeightG}
                  onChange={(event) => {
                    setBrakeWeightG(event.target.value);
                    validateBrakeWeightG(parseInt(event.target.value));
                  }}
                  InputProps={{ inputProps: { min: 0, max: 99999 } }}
                  helperText={
                    !validation.brakeWeightG ? "The brake weight must be between 1-9999" : ""
                  }
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Tooltip enterDelay={750} arrow title="Weight of the Platform-operated handbrake.">
                <TextField
                  required
                  label="Brake Weight P (in tonnes)"
                  type="number"
                  value={brakeWeightP}
                  error={!validation.brakeWeightP}
                  onChange={(event) => {
                    setBrakeWeightP(event.target.value);
                    validateBrakeWeightP(parseInt(event.target.value));
                  }}
                  InputProps={{ inputProps: { min: 0, max: 99999 } }}
                  helperText={
                    !validation.brakeWeightP ? "The brake weight must be between 1-9999" : ""
                  }
                  variant="outlined"
                  fullWidth
                />
              </Tooltip>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disabled={validateForm()} onClick={submitForm}>
          {title}
        </Button>
        <Button onClick={onHide} color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
