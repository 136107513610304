import React, { ReactElement } from "react";
import { Button, Divider, Stack, SxProps, Theme, Typography } from "@mui/material";
import { hasPermissions } from "../../utils/scopeChecker";
import { Add } from "@mui/icons-material";

interface Props {
  title: string;
  buttonMethod?: (() => void) | null;
  buttonText?: string | null;
  size?: number | undefined;
  permissions?: string[];
  icon?: ReactElement;
  sxDivider?: SxProps<Theme>;
}

const TITLE_STYLE = {
  position: "relative",
  "&::after": {
    position: "absolute",
    content: "''",
    display: "block",
    mt: "6px",
    height: 5,
    width: "100%",
    bgcolor: "secondary.main",
    zIndex: 2,
    borderRadius: "20px",
  },
  fontSize: 32,
};

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    heading: true;
  }
}

/**
 * A reusable title component which has an optional button at the right side when providing a button method and -text.
 *
 * @param {String} title
 * @param {Option<Function>} buttonMethod
 * @param {Option<String>} buttonText
 * @param {Array<String>} permissions
 * @param {JSX.Element} icon
 * @param {Object} sxDivider
 * @returns {JSX.Element}
 * @constructor
 */
export default function TitleBar({
  title,
  buttonMethod = null,
  buttonText = null,
  permissions = [],
  icon = <Add />,
  sxDivider,
}: Props): JSX.Element {
  return (
    <>
      <Stack direction={"row"}>
        <Typography sx={TITLE_STYLE} variant={"h2"} fontWeight={"500"}>
          {title}
        </Typography>
        {hasPermissions(permissions) && buttonText && buttonMethod && (
          <Button
            sx={{ ml: "auto" }}
            size={"small"}
            variant={"outlined"}
            endIcon={icon}
            onClick={buttonMethod}
          >
            <Typography variant={"heading"}>{buttonText}</Typography>
          </Button>
        )}
      </Stack>
      <Divider sx={sxDivider || { mb: 6, mt: 1 }} />
    </>
  );
}
