import React, { useState } from "react";
import { TRAIN_TYPES } from "../../../utils/constants";
import JourneysPicker from "./journeys/journeyspicker/JourneysPicker";
import CreateTraction from "./composition/createTraction/CreateTraction";
import CreateWagon from "./composition/createWagon/CreateWagon";
import { Train } from "@mui/icons-material";
import { Divider, Stack } from "@mui/material";
import TrainState from "./TrainState/TrainState";
import TreeViewComponent from "../../../components/Journey/JourneyTreeView/TreeViewComponent";
import treeData  from "../../../../src/utils/TCMData.json";
import { formatDate } from "../../../utils/formateDate";

export interface Train {
  id: number;
  links: {
    rel: string;
    title: string;
    href: string;
  }[];
  name: string; 
  journeySections : Journey[]; 
  transferPoint: string;
  operationalTrainNumber?: string;
  trainType?: string;
  scheduledTimeAtHandover?: string;
}

export interface Journey {
  id: number;
  order: number;
  links: {
    rel: string;
    title: string;
    href: string;
  }[];
  tractions: any[];
  wagons: any[];
  disabled: boolean;
  trainComposition?: {
    livestockOrPeopleIndicator: number,
    rollingStock: unknown[];
  };
  activities?: any;
}

interface InnerbarProps {
  train: Train;
  setShowCreateJourney: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditJourney: React.Dispatch<React.SetStateAction<boolean>>;
  selectedJourney: Journey | undefined;
  setShowCreateTraction: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCreateWagon: boolean;
  setSelectedJourney: React.Dispatch<React.SetStateAction<Journey | undefined>>;
  setTrain: React.Dispatch<React.SetStateAction<Train | undefined>>; // Updated prop type
  setJourneyAndTrainHandler: (value: Journey) => void;
  showEditMode: (item: any, selectedJourney: any) => boolean;
  fetchTrain: () => Promise<void>;
  getToken: () => string;
  // deleteJourney?: (journey: Journey) => void;
  onTractionUpdated: (journey: Journey) => void;
  onWagonUpdated: (journey: Journey) => void;
}
export const Innerbar: React.FC<InnerbarProps> = ({
  train,
  setShowCreateJourney,
  setShowEditJourney,
  setSelectedJourney,
  setTrain,
  setJourneyAndTrainHandler,
  showEditMode,
  fetchTrain,
  getToken,
  // deleteJourney,
  onTractionUpdated,
  onWagonUpdated
}) => {
  const [showCreateTraction, setShowCreateTraction] = useState(false);
  const [showCreateWagon, setShowCreateWagon] = useState(false);
  const [journey, setJourney] = useState<Journey>();

  function showCreateTractionHandler(journey: Journey): Promise<void> {
    setShowCreateTraction(true);
    setJourney(journey);
    return Promise.resolve();
  }

  function showCreateWagonHandler(journey: Journey): Promise<void> {
    setShowCreateWagon(true);
    setJourney(journey);
    return Promise.resolve();
  }

  return (
    <>
      <Stack
        sx={{ bgcolor: "rgba(202, 206, 231, 0.14)", p: 2, mb: 8 }}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <span>
          <Train sx={{ color: "secondary.light" }} />
          Train
        </span>
        <span>{train.operationalTrainNumber}</span>
        <span>{TRAIN_TYPES[train.trainType! as any].summary}</span>
        <span>{formatDate(train.scheduledTimeAtHandover!)}</span>
      </Stack>
      <JourneysPicker
        train={train}
        // deleteJourney={deleteJourney}
        setShowCreateJourney={setShowCreateJourney}
        createTractionHandler={showCreateTractionHandler}
        createWagonHandler={showCreateWagonHandler}
        setShowEditJourney={setShowEditJourney}
        selectedJourneyHandler={setSelectedJourney}
        getToken={getToken as any}
        setShowCreateWagon={setShowCreateWagon}
        setTrain={setTrain}
        setJourneyAndTrainHandler={setJourneyAndTrainHandler}
        showEditMode={showEditMode}
        fetchTrain={fetchTrain}
      />
      <Divider sx={{ mt: 1, mb: 5, ml: 5.5, mr: 4, }}/>
      <TrainState/>
      {showCreateTraction && <CreateTraction
        getToken={async () => getToken()}
        onHide={() => setShowCreateTraction(false)}
        selectedJourney={journey}
        setSelectedJourney={onTractionUpdated}
      />}
      {showCreateWagon &&
        <CreateWagon
          getToken={async () => getToken()}
          onHide={() => setShowCreateWagon(false)}
          selectedJourney={journey}
          setSelectedJourney={onWagonUpdated}
        />
      }
      <Divider sx={{ mt: 1, mb: 5, ml: 5.5, mr: 4, }}/>
      <TreeViewComponent data={treeData}/>
      <Divider sx={{ mt: 5, mb: 5, ml: 5.5, mr: 4, }}/>
    </>
  );
};
