import React, { useEffect, useState } from "react";
import TractionService from "../../../../../api/tractions";
import TrainCompositionService from "../../../../../api/traincomposition";
import { errorAlert, succeedAlert } from "../../../../../utils/Alerts";
import JourneySectionService from "../../../../../api/journeysections";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";

interface Props {
  onHide: () => void;
  selectedJourney: any;
  setSelectedJourney: (data: any) => void;
  getToken: () => Promise<string>;
  editData?: any;
}

export default function CreateTraction({
  onHide,
  selectedJourney,
  setSelectedJourney,
  getToken,
  editData,
}: Props) {
  useEffect(() => {
    // Fetch tractions
    const fetchTractions = async () => {
      setTractions((prevState) => ({ ...prevState, isFetching: true }));
      const { data, error } = await TractionService.getTractions(await getToken());
      if (data) {
        setTractions((prevState) => ({
          ...prevState,
          isFetching: false,
          tractions: data,
        }));
      } else {
        setTractions((prevState) => ({ ...prevState, isFetching: false, error }));
      }
    };
    fetchTractions();
    if (editData) {
      setTractionHandler(editData.traction);
      setHasDriverHandler(editData.driverIndication);
    }
  }, [getToken, editData]);

  const [tractions, setTractions] = useState<{
    tractions: any[];
    isFetching: boolean;
    error: string;
  }>({ tractions: [], isFetching: false, error: "" });
  const [form, setForm] = useState<{
    traction: any;
    hasDriver: boolean;
    isSubmitting: boolean;
    error: string;
  }>({ traction: "", hasDriver: false, isSubmitting: false, error: "" });

  const setTractionHandler = (traction: any) => {
    setForm((prevState) => ({ ...prevState, traction }));
  };

  const setHasDriverHandler = (hasDriver: boolean) => {
    setForm((prevState) => ({ ...prevState, hasDriver }));
  };

  const validateForm = () => {
    if (form.traction && !form.isSubmitting) {
      return false;
    }
    return true;
  };

  const submitForm = async (event: React.MouseEvent) => {
    event.preventDefault();
    const selectedLocoTypeNumber = form.traction.locoTypeNumber;
    if (!editData) {
      const containsStock = selectedJourney.trainComposition.rollingStock.some((stock: any) => {
        return stock?.traction?.locoTypeNumber === selectedLocoTypeNumber;
      });

      if (containsStock) {
        return setForm((prevState) => ({
          ...prevState,
          error: "Stock exists in current composition",
        }));
      }
    }
    setForm((prevState) => ({ ...prevState, isSubmitting: true, error: "" }));
    const trainCompositionId = selectedJourney.trainComposition.id;

    const body = {
      stockType: "traction",
      driverIndication: form.hasDriver,
      stockIdentifier: parseInt(selectedLocoTypeNumber),
    };

    const { error } = editData
      ? await TrainCompositionService.updateStock(
        trainCompositionId,
        editData.id,
        body,
        await getToken(),
      )
      : await TrainCompositionService.saveStock(trainCompositionId, body, await getToken());
    if (error) {
      errorAlert(error);
      setForm((prevState) => ({ ...prevState, error, isSubmitting: false }));
    } else {
      const { data, error } = (await JourneySectionService.getJourneySectionById(
        selectedJourney.id,
        await getToken(),
      )) as any;
      if (error) {
        setForm((prevState) => ({ ...prevState, error, isSubmitting: false }));
        errorAlert(error);
      } else {
        setSelectedJourney(data);
        setForm((prevState) => ({ ...prevState, isSubmitting: false }));
        succeedAlert();
        resetForm();
        onHide();
      }
    }
  };

  const resetForm = () => {
    setForm({ traction: "", hasDriver: false, isSubmitting: false, error: "" });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"xs"}
      open={true}
      onClose={onHide}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{`${editData ? "Edit" : "Add"} Traction`}</DialogTitle>
      <DialogContent>
        {tractions.isFetching && (
          <div className="circular-progress">
            <CircularProgress />
          </div>
        )}
        {!tractions.isFetching && (
          <form>
            <Grid container mt={-2} spacing={3}>
              <Grid item xs={12}>
                <Autocomplete
                  value={form.traction || null}
                  options={tractions.tractions}
                  loading={tractions.isFetching}
                  onChange={(event, value) => setTractionHandler(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose a Traction ..." variant="outlined" />
                  )}
                  getOptionLabel={(traction) => `${traction.locoNumber} - ${traction.typeName}`}
                  isOptionEqualToValue={(option, value) => option.locoNumber === value.locoNumber}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.hasDriver}
                        onChange={(event) => setHasDriverHandler(event.target.checked)}
                      />
                    }
                    label="Has a driver"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            {/* Locomotives */}
            {/* <div className="label-pos">
                    <Typeahead
                        clearButton
                        id="basic-typeahead-example"
                        labelKey={option => `${option.locoNumber} - ${option.typeName}`}
                        onChange={(value) => setTractionHandler(value)}
                        options={tractions.tractions}
                        placeholder="Choose a Traction..."
                        selected={form.traction}
                        filterBy={['locoNumber', 'typeName']}
                        isLoading={tractions.isFetching}
                    />
                    <label className="ct-label" htmlFor="basic-typeahead-example">
                        <li className="fas fa-text-height"></li>
                    </label>
                </div>

                <div className="label-pos d-flex align-items-center">
                    <input onChange={(event) => setHasDriverHandler(event.target.checked)} checked={form.hasDriver} className="w-auto" type="checkbox" id="lifestock" />
                    <label className="ml-2 mb-0" htmlFor="lifestock">Driver</label>
                </div>

                <div className="btn-submit">
                    <button style={{ cursor: validateForm() ? 'no-drop' : 'pointer' }} disabled={validateForm()}
                        type="submit">
                        ADD
                    </button>
                </div>
 */}
            {form.error && <div className="mt-2 text-danger">{form.error}</div>}
            {tractions.error && <div className="mt-2 text-danger">{tractions.error}</div>}
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={validateForm()}
          onClick={async (e) => {
            await submitForm(e);
          }}
        >
          {editData ? "Edit" : "Add"}
        </Button>
        <Button onClick={onHide} color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
