export function hasPermissions(scopes: any) {
  const userScopes = localStorage.getItem("scopes");
  if (userScopes) {
    const parsedScopes = JSON.parse(userScopes);
    const isAuthorized = scopes.some((element: any) => {
      return parsedScopes.includes(element);
    });
    return isAuthorized;
  }
  return false;
}
