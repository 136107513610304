import React from "react";
import DataTable, { Alignment } from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { hasPermissions } from "../../../utils/scopeChecker";
import { Stack } from "@mui/material";
import { Filter, Spinner } from "../../../components";
import { Customer } from "../../../models/Customer";

interface CustomerTableProps {
  customers: Customer[];
  onDeleteCustomer: (customer: Customer) => void;
  onEditCustomer: (customer: Customer) => void;
  isFetching: boolean;
}

export default function CustomerTable({
  customers,
  onDeleteCustomer,
  onEditCustomer,
  isFetching,
}: CustomerTableProps) {
  const columns = [
    {
      name: "Name",
      selector: (row: Customer) => row.customername,
      sortable: true,
    },
    {
      name: "KvK",
      selector: (row: Customer) => row.kvk,
      sortable: true,
    },
    {
      name: "IBAN",
      selector: (row: Customer) => row.iban,
      sortable: true,
    },
    {
      name: "Company Code",
      selector: (row: Customer) => row.companyCode,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row: Customer) => row.company.name,
      sortable: true,
    },
  ];

  /**
   * insert de columns en op basis van de permissions die de user in de auth0 token heeft worden delete/edit knoppen toegevoegd per rij
   */
  const getColumns = () => {
    if (hasPermissions(["write:user" && "delete:user"])) {
      const buttonsColumn = {
        cell: (row: Customer) => (
          <Stack spacing={1} direction={"row"}>
            <Button
              className={"text-nowrap"}
              variant="outline-danger"
              size="sm"
              onClick={() => onDeleteCustomer(row)}
            >
              Delete
            </Button>
            <Button
              className={"text-nowrap"}
              variant="outline-secondary"
              size="sm"
              onClick={() => onEditCustomer(row)}
            >
              Edit
            </Button>
          </Stack>
        ),
        ignoreRowClick: true,
        right: true,
      };

      return [...columns, buttonsColumn];
    } else if (hasPermissions(["write:user"])) {
      const editColumn = {
        cell: (row: Customer) => (
          <Button
            className={"text-nowrap"}
            variant="outline-secondary"
            size="sm"
            onClick={() => onEditCustomer(row)}
          >
            Edit
          </Button>
        ),
        ignoreRowClick: true,
        button: true,
        right: true,
      };
      return [...columns, editColumn];
    } else {
      return columns;
    }
  };

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  /**
   * filter voor table contents
   */
  const filteredCustomers = customers.filter(
    (customer) =>
      (customer.customername &&
        customer.customername.toLowerCase().includes(filterText.toLowerCase())) ||
      (customer.companyCode &&
        customer.companyCode.toLowerCase().includes(filterText.toLowerCase())) ||
      (customer.iban && customer.iban.toLowerCase().includes(filterText.toLowerCase())),
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        placeholder={"Filter by Customer"}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={getColumns()}
      defaultSortFieldId="scheduledTimeAtHandover"
      data={filteredCustomers}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      subHeaderAlign={Alignment.RIGHT}
      persistTableHead
      highlightOnHover
      noHeader={true}
      pointerOnHover={true}
      progressPending={isFetching}
      progressComponent={<Spinner />}
    />
  );
}
