import React, { useState } from "react";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Fade,
  Stack,
  Typography,
} from "@mui/material";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import Statistic from "../../Statistic/Statistic";
import { hasPermissions } from "../../../utils/scopeChecker";
import { Journey } from "../../../models/Journey";

const DEFAULT_TITLE = "No title specified...";

interface JourneySectionTimelineItemProps {
  accordionTitle: string;
  journey?: Journey;
  onDeleteClick?: (...args: any[]) => void;
  onEditClick?: (...args: any[]) => void;
  onSummaryFocus?: (...args: any[]) => void;
  disabled?: boolean;
  children?: JSX.Element;
}

/**
 * A reusable accordion components with summary information about the journey:
 *  - Does it have dangerous goods?
 *  - How many vehicles does it have?
 *  - Where is it located?
 *  - Is it removable?
 *  - Is it editable?
 *
 *  This component handles the opening and closing of the accordion,
 *  no need for the user of this component to handle it themselves.
 *
 * @param {String} accordionTitle
 * @param {Object} journey
 * @param {Function<void>} onDeleteClick
 * @param {Function<void>} onEditClick
 * @param {Function<void>} onSummaryFocus
 * @param {boolean} disabled
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
export default function JourneySectionTimelineItem({
  accordionTitle = DEFAULT_TITLE,
  journey,
  onDeleteClick,
  onEditClick,
  onSummaryFocus,
  disabled = false,
  children,
}: JourneySectionTimelineItemProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const trainComposition = journey?.trainComposition;

  const DangerousGoods = () => {
    if (trainComposition?.containsDangerousGoods) {
      return (
        <Statistic
          description={"Dangerous goods in composition"}
          iconName={"warning"}
          size={"medium"}
          color={"warning"}
        />
      );
    }

    return <></>;
  };

  const Vehicles = () => {
    if (trainComposition?.numberOfVehicles) {
      return (
        <Statistic
          description={"Number of vehicles"}
          iconName={"wagons"}
          value={trainComposition?.numberOfVehicles.toString()}
        />
      );
    }

    return <></>;
  };

  const Buttons = () => {
    if (disabled) {
      return <></>;
    }

    return (
      <>
        {hasPermissions(["write:journeysection"]) && (
          <Button
            color="primary"
            variant="outlined"
            size={"small"}
            startIcon={<Edit />}
            onClick={(event) => {
              event.stopPropagation();
              onEditClick && onEditClick(journey);
            }}
          >
            Edit
          </Button>
        )}
        <Button
          color="error"
          variant="outlined"
          size={"small"}
          startIcon={<Delete />}
          onClick={(event) => {
            event.stopPropagation();
            onDeleteClick && onDeleteClick(journey);
          }}
        >
          Delete
        </Button>
      </>
    );
  };

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={"grey"} variant={"outlined"} />
        {!disabled ? <TimelineConnector /> : <></>}
      </TimelineSeparator>
      <TimelineContent>
        <Accordion disabled={disabled} expanded={expanded}>
          <AccordionSummary
            className={"d-flex"}
            expandIcon={<ExpandMore />}
            onClick={() => setExpanded(!expanded)}
            onFocus={onSummaryFocus}
          >
            <Typography className={"d-flex align-items-center"} variant={"h6"} component={"h2"}>
              {accordionTitle}
            </Typography>
            <Fade in={!expanded}>
              <Stack direction={"row"} alignItems={"center"} spacing={1} className={"ms-auto"}>
                <DangerousGoods />
                <Vehicles />
              </Stack>
            </Fade>
            <Stack direction="row" spacing={2} className={"mx-3"}>
              <Buttons />
            </Stack>
          </AccordionSummary>
          <AccordionDetails>{children ?? <></>}</AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
}
