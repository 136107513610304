import React, { useState } from "react";
import { Autocomplete, Button, Card, CardContent, Grid, TextField } from "@mui/material";
import { TitleBar } from "../../../../../../components";

type DangerGoodsType = {
  id: number;
  unNumber: string;
  hazardIdentificationNumber: string;
  description: string;
};

type FormProps = {
  setForm: React.Dispatch<React.SetStateAction<any>>;
  dangerGoodsTypes: {
    data: DangerGoodsType[];
    isFetching: boolean;
  };
  form: {
    selectedDangerGoodsType: DangerGoodsType;
    weight: string;
    showForm: boolean;
  };
  add: () => void;
};

export default function Form({ setForm, dangerGoodsTypes, form, add }: FormProps) {
  const setWeightHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const weight = e.target.value;
    validateLoadWeight(weight);
    setForm((prevState : any) => ({ ...prevState, weight }));
  };
  const initialValidation = {
    loadWeight: true,
  };

  function validateLoadWeight(value: string) {
    setValidation((prevState : any) => ({ ...prevState, loadWeight: value && +value >= 0 && value.toString().length <= 3 }));
  }
  const [validation, setValidation] = useState(initialValidation);

  const validate = () => {
    return form.selectedDangerGoodsType && parseInt(form.weight) > 0 && validation.loadWeight;
  };

  return (
    <Card>
      <CardContent>
        <TitleBar
          sxDivider={{ mb: 3, mt: 1 }}
          title={"Add dangerous good"}
          size={undefined}
          buttonMethod={() => setForm((prevState : any) => ({ ...prevState, showForm: false }))}
          permissions={["write:journeysection"]}
        />
        <Grid container spacing={3} className="form-grid">
          <Grid item xs={12}>
            <Autocomplete
              options={dangerGoodsTypes.data}
              getOptionLabel={(goodType: DangerGoodsType) =>
                `${goodType.unNumber} - ${goodType.hazardIdentificationNumber} - ${goodType.description} - ${goodType.id}`
              }
              value={form.selectedDangerGoodsType}
              onChange={(event, value) =>
                setForm((prevState : any) => ({ ...prevState, selectedDangerGoodsType: value }))
              }
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              renderInput={(params) => <TextField {...params} label="Select a danger good type" variant="outlined" />}
              loading={dangerGoodsTypes.isFetching}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-loadweight"
              label="Load weight (in kilos)"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              name="loadweight"
              helperText={!validation.loadWeight ? "The load weight must be between 1-999" : ""}
              InputProps={{ inputProps: { min: 0, max: 999999 } }}
              value={form.weight}
              onChange={setWeightHandler}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={add} disabled={!validate()}>
              Add
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
