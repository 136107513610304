import { ENDPOINTS } from "../utils/constants";
import FetchService from "./fetchservice";

class TrainService extends FetchService {
  getTrains(token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  getJourney(token: string, trainId: number) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}/${trainId}/journeysections`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  sendTcm(token: string, trainId: number) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}/${trainId}/send`, "POST", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  saveTrain(body: any, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}`, "POST", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  getTrain(trainid: number, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}/${trainid}`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  editTrain(trainid: number, body: any, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}/${trainid}`, "PUT", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  deleteTrain(trainId: number, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}/${trainId}`, "DELETE", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  saveJourney(trainid: number, body: any, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}/${trainid}/journeysections`, "POST", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  editJourney(url: string, body: any, token: string) {
    return super
      .fetch(url, "PUT", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  deleteJourney(trainid: number, journeyId: number, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINS}/${trainid}/journeysections/${journeyId}`, "DELETE", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }
}

export default new TrainService();
