import React, { useEffect, useState } from "react";
import DangerGoodsTypesService from "../../../../../../api/dangergoodstypes";
import Form from "./Form";
import DataTable from "react-data-table-component";
import { Button, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { JourneyDetailedDangerIcon, TitleBar } from "../../../../../../components";

interface Props {
  dangerGoods: { index: number; data: any; weight: string }[];
  add: (dangerGood: { index: number; data: any; weight: string }) => void;
  remove: (index: number) => void;
  getToken: () => Promise<string>;
  size: number;
}

interface FormState {
  selectedDangerGoodsType: any;
  weight: string;
  showForm: boolean;
}

interface DangerGoodsTypes {
  data: any[];
  isFetching: boolean;
  error: string;
}

export default function DangerousGoods({ dangerGoods, add, remove, getToken }: Props) {
  const [dangerGoodsTypes, setDangerGoodsTypes] = useState<DangerGoodsTypes>({
    data: [],
    isFetching: false,
    error: "",
  });
  const initialForm: FormState = { selectedDangerGoodsType: null, weight: "0", showForm: false };
  const [form, setForm] = useState<FormState>(initialForm);

  const columns = [
    {
      name: "RID",
      cell: (row: any) => <JourneyDetailedDangerIcon dangerGoodsType={row.data} />,
      grow: 0,
      selector: (row: any) => row.data.ridClass,
      sortable: true,
    },
    {
      name: "Weight",
      selector: (row: any) => row.weight,
      cell: (row: any) => <div>{row.weight} KG</div>,
      sortable: true,
    },
    {
      name: "HazardID",
      selector: (row: any) => row.data.hazardIdentificationNumber,
      sortable: true,
    },
    {
      name: "unNumber",
      selector: (row: any) => row.data.unNumber,
      sortable: true,
    },
    {
      cell: (row: any) => (
        <Button
          className={"text-nowrap"}
          variant={"outlined"}
          color={"error"}
          size="small"
          onClick={() => remove(row.index)}
        >
          Remove
        </Button>
      ),
      button: true,
      right: true,
    },
  ];

  const ExpandedComponentDangerousGoods = ({ data }: { data: any }) => (
    <div className={"p-2 bg-light"}>
      <Stack direction="row" spacing={1}>
        <Typography className={"fw-bold"}>Description:</Typography>
        <Typography variant="body1">{data.data.description}</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography className={"fw-bold"}>Packing group:</Typography>
        {data.data.packingGroup ? (
          <Typography>{data.data.packingGroup}</Typography>
        ) : (
          <Typography className={"fst-italic"}>Unknown</Typography>
        )}
      </Stack>
    </div>
  );

  useEffect(() => {
    const fetchDangerGoodsTypes = async () => {
      setDangerGoodsTypes(prevState => ({ ...prevState, isFetching: true, error: "" }));
      try {
        const { data, error } = await DangerGoodsTypesService.getAll(await getToken());
        if (data) {
          return setDangerGoodsTypes(prevState => ({ ...prevState, data, isFetching: false }));
        }
        throw new Error(error.message);
      } catch (e : any) {
        const error = (e instanceof String) ? e : e.message;
        return setDangerGoodsTypes(prevState => ({ ...prevState, error, isFetching: false }));
      }
    };
    fetchDangerGoodsTypes();
  }, []);

  const addDangerGoodHandler = () => {
    setForm((prevState) => ({
      ...prevState,
      selectedDangerGoodsType: initialForm.selectedDangerGoodsType,
      weight: initialForm.weight
    }));
    add({ index: dangerGoods.length, data: form.selectedDangerGoodsType, weight: form.weight });
  };

  return (
    <>
      {!dangerGoodsTypes.error ?
        <>
          <TitleBar
            sxDivider={{ mb: 3, mt: 1 }}
            size={16}
            title={"Dangerous Goods"}
            buttonText={form.showForm ? "Close" : "Add Dangerous Goods"}
            icon={form.showForm ? <CloseIcon/> : undefined}
            buttonMethod={() => setForm(prevState => ({ ...prevState, showForm: !prevState.showForm }))}
            permissions={["write:journeysection"]}/>
          <DataTable
            columns={columns}
            data={dangerGoods}
            pagination
            highlightOnHover
            pointerOnHover={true}
            expandOnRowClicked
            noDataComponent={"There are no dangerous goods on this wagon..."}
            expandableRows
            expandableRowsComponent={ExpandedComponentDangerousGoods}
          />
        </>
        : <span style={{ fontSize: "13px" }}>{dangerGoodsTypes.error}</span>
      }
      {form.showForm && <Form
        setForm={setForm}
        dangerGoodsTypes={dangerGoodsTypes}
        form={form}
        add={addDangerGoodHandler}
      />}
    </>
  );
}
