import { ENDPOINTS } from "../utils/constants";
import FetchService from "./fetchservice";

class WagonService extends FetchService {
  getWagons(token: string): Promise<any> {
    return super
      .fetch(`${ENDPOINTS.WAGONS}`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  saveWagon(body: any, token: string): Promise<any> {
    return super
      .fetch(`${ENDPOINTS.WAGONS}`, "POST", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  editWagon(wagonId: string, body: any, token: string): Promise<any> {
    return super
      .fetch(`${ENDPOINTS.WAGONS}/${wagonId}`, "PUT", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  deleteWagon(wagonId: string, token: string): Promise<any> {
    return super
      .fetch(`${ENDPOINTS.WAGONS}/${wagonId}`, "DELETE", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }
}

export default new WagonService();
