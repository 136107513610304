import React from "react";
import Statistics from "./statistics/Statistics";
import CloneComposition from "./cloneComposition/CloneComposition";
import { hasPermissions } from "../../../../utils/scopeChecker";
import TrainCompositionService from "../../../../api/traincomposition";
import { Box, Button, Stack, Typography, SvgIcon } from "@mui/material";
import iconNames from "../../../../utils/icons/SVGIcons";
import { JourneyVehicleDragDropContext } from "../../../../components";
import { Wagon } from "../../../../utils/icons/composition";

interface Train {
  id: number;
  name: string;
  transferPoint: string;
}

type Props = {
  selectedJourney: any;
  createTractionHandler: (selectedJourney: any) => void;
  createWagonHandler: (selectedJourney: any) => void;
  setTrain: (train: any) => void;
  setShowCreateWagon: (wagon: any) => void;
  setJourneyAndTrainHandler: (updatedSelectedJourney: any) => void;
  showEditMode: (item: any, selectedJourney: any) => boolean;
  fetchTrain: () => void;
  getToken: () => Promise<string>;
  setCopiedJourneySectionUrl: (url: string) => void;
  copiedJourneySectionUrl: string;
  train: Train;
}

export default function Composition({
  selectedJourney,
  createTractionHandler,
  createWagonHandler,
  setTrain,
  setJourneyAndTrainHandler,
  showEditMode,
  fetchTrain,
  getToken,
  setCopiedJourneySectionUrl,
  copiedJourneySectionUrl
}: Props) {

  const setTrainCompositionHandler = async (trainCompositionId: any, stockId: any) => {
    const { error } = await TrainCompositionService.deleteStock(trainCompositionId, stockId, await getToken());
    if (error) throw new Error(error);

    const result = await TrainCompositionService.getComposition(trainCompositionId, await getToken());
    if (!result.data) throw new Error(error);

    let newComposition = result.data;
    const updatedSelectedJourney = { ...selectedJourney, trainComposition: newComposition };
    setJourneyAndTrainHandler(updatedSelectedJourney);
  };

  return (
    <>
      {selectedJourney &&
      <div style={{ clear: "both" }}>
        <Statistics selectedJourney={selectedJourney}/>
      </div>
      }
      <Box pt={2} pb={1}>
        {selectedJourney &&
          <Stack alignItems="center" direction={"row"}>
            <Typography sx={{ alignItems: "center", mr: "auto" }} variant={"heading"}>Composition</Typography>
            {hasPermissions(["write:traincomposition"]) && <CloneComposition
              getToken={() => getToken()}
              selectedJourney={selectedJourney}
              setTrain={setTrain}
              setCopiedJourneySectionUrl={setCopiedJourneySectionUrl}
              copiedJourneySectionUrl={copiedJourneySectionUrl}
            />}
            {hasPermissions(["write:rollingstock"]) && <>
              <Button
                className={"text-nowrap me-2"}
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<SvgIcon fontSize="large">{iconNames["traction"][0]()}</SvgIcon>}
                onClick={() => createTractionHandler(selectedJourney)}>
                ADD TRACTION
              </Button>
              <Button
                className={"text-nowrap"}
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<SvgIcon fontSize="large">{Wagon()}</SvgIcon>}
                onClick={() => createWagonHandler(selectedJourney)}>
                ADD WAGON
              </Button>
            </>}
          </Stack>
        }
      </Box>

      {selectedJourney &&
      <>
        <JourneyVehicleDragDropContext
          getToken={() => getToken()}
          showEditMode={showEditMode}
          selectedJourney={selectedJourney}
          setTrainCompositionHandler={setTrainCompositionHandler}
          fetchTrain={fetchTrain}
        />
      </>
      }
    </>
  );
}
