import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from "@mui/material";
import { grey } from "@mui/material/colors";
import iconNames from "../../../utils/icons/SVGIcons";
import { Wagon } from "../../../utils/icons/composition";

const DEFAULT_LINK_STYLE = {
  display: "block",
  textDecoration: "none",
};
const HIGHLIGHTED_LINK_STYLE = {
  background: "linear-gradient(to left, transparent, rgba(255, 255, 255, 0.08))",
  boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1)",
};

interface SidebarNavigationLinkProps {
  item: any;
}



/**
 * A reusable navigation component, which handles incoming clicks and navigates the user to the right page.
 *
 * @param {Object} item
 * @returns {JSX.Element}
 * @constructor
 */
export default function SidebarNavigationLink({ item }: SidebarNavigationLinkProps): JSX.Element {
  const iconElement = iconNames[item.icon as keyof typeof iconNames];
  let renderIcon;
  if (Array.isArray(iconElement)) {
    if (item.icon === "traction") {
      renderIcon = iconElement[0](); 
    } else {
      renderIcon = Wagon();
    }
  } else if (typeof iconElement === "function") {
    renderIcon = iconElement();
  }
  return (
    <NavLink
      style={({ isActive }) =>
        isActive ? { ...DEFAULT_LINK_STYLE, ...HIGHLIGHTED_LINK_STYLE } : { ...DEFAULT_LINK_STYLE }
      }
      to={item.path}
      onClick={() => item?.onClick?.()}
    >
      <ListItem disablePadding>
        <ListItemButton sx={{ color: grey[900] }}>
          <ListItemIcon sx={{ color: "primary.light", display: "flex", alignItems: "center" }}>
            <SvgIcon >{renderIcon}</SvgIcon>
            <ListItemText sx={{ ml: 1 }} primary={item.text} />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </NavLink>
  );
}
