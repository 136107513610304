import React, { useEffect, useState } from "react";
import { errorAlert, succeedAlert } from "../../../utils/Alerts";
import UserService from "../../../api/user";
import { Box, Button, LinearProgress, MenuItem, Stack, TextField } from "@mui/material";
import CustomerService from "../../../api/customer";
import { Role } from "../../../models/Role";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface ManageUserProps {
  rolelist: Role[];
  onSave: (cb: (prevState: any) => any) => void;
  userDTO: any;
  customerDTO: any;
  getToken: () => Promise<string>;
  editUserHandler: () => void;
  mode: string;
}

export default function ManageUser({
  rolelist,
  onSave,
  userDTO,
  customerDTO,
  getToken,
  editUserHandler,
  // TODO: Temporary field that removes some of the code duplication, replace with something more flexible.
  mode = "user",
}: ManageUserProps) {
  const [isFetching, setFetching] = useState(false);
  const [editMode, setEditMode] = useState(!!userDTO);
  const [title, setTitle] = useState("CREATE");
  const [firstname, setFirstname] = useState(userDTO ? userDTO.firstname : "");
  const [lastname, setLastname] = useState(userDTO ? userDTO.lastname : "");
  const [email, setEmail] = useState(userDTO ? userDTO.email : "");
  const [role, setRole] = useState(userDTO ? userDTO.role : "");

  useEffect(() => {
    if (userDTO) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [userDTO]);

  useEffect(() => {
    if (userDTO) {
      setTitle("EDIT");
    } else {
      setTitle("CREATE");
    }
  }, [userDTO]);

  const submitForm = async (event: any) => {
    event.preventDefault();
    setFetching(true);

    const body = {
      userId: userDTO?.userId,
      customerId: userDTO?.customerId ?? customerDTO?.id,
      firstname: firstname,
      lastname: lastname,
      email: email,
      role: role,
    };

    let result: any;
    if (editMode) {
      result = await UserService.update(await getToken(), body);
    } else {
      result =
        mode !== "customer"
          ? await UserService.save(await getToken(), body)
          : await CustomerService.saveUser(await getToken(), body);
    }

    try {
      if (result.data) {
        if (userDTO) {
          onSave((prevState: any) => {
            const newList = prevState.data.map((item: any) => {
              if (item.userId === result.data.userId) {
                return result.data;
              } else {
                return item;
              }
            });
            return { ...prevState, data: newList };
          });
        } else {
          onSave((prevState) => ({ ...prevState, data: [...prevState.data, result.data] }));
        }
        succeedAlert();
        setFetching(false);
        editUserHandler();
      } else {
        throw new Error(result.message);
      }
    } catch (e) {
      errorAlert(result.error);
    }
    setFetching(false);
  };

  return (
    <div>
    <Dialog open={true} onClose={(event, reason) => {
      if (reason !== "backdropClick") {
        editUserHandler();
      }
    }} aria-labelledby="form-dialog-title" className="dialog" >
    <DialogTitle id="form-dialog-title"  style={{ width: "500px" }}>{userDTO ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent className={"overflow-visible"}>
    <Box component={"form"} onSubmit={submitForm}>
      <Stack spacing={2}>
        <TextField
          label={"First Name"}
          fullWidth
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          required
        />
        <TextField
          label={"Last Name"}
          fullWidth
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          required
        />
        <TextField
          label={"Email"}
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          label={"Role"}
          value={role}
          onChange={(e) => setRole(e.target.value)}
          select
          required
        >
          {rolelist.map((role: Role) => (
            <MenuItem key={role.value} value={role.name}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={submitForm}>
            {userDTO ? "EDIT" : "CREATE"} 
          </Button>
          <Button onClick={editUserHandler} color="inherit">
            Cancel
          </Button>
        </DialogActions>
        </Stack>
    </Box>
    </DialogContent>
    </Dialog>
    </div>

  );
}
