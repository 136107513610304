import React from "react";
import { Popover, Stack } from "@mui/material";
import { JourneyDetailedDangerIcon } from "../";
import { DangerGood } from "../../../models/DangerGood";
// eslint-disable-next-line no-unused-vars
import { DangerGoodsType } from "../../../models/DangerGoodsType";

interface JourneyDetailedDangerIconsPopoverProps {
  anchorElement: HTMLElement;
  dangerGoods: DangerGood[];
  show: boolean;
  handleClose: () => void;
}

/**
 * A reusable popover that will display the expanded danger icons mentioned with added classification.
 *
 * The popover will only show unique classifications, it removes duplicate entries. These entries are sorted by their
 * classification number, ascending.
 *
 * @param {HTMLElement} anchorElement
 * @param {Array<Object>} dangerGoods
 * @param {boolean} show
 * @param {Function<void>} handleClose
 * @returns {JSX.Element}
 * @constructor
 */
export default function JourneyDetailedDangerIconsPopover({
  anchorElement,
  dangerGoods = [],
  show = false,
  handleClose,
}: JourneyDetailedDangerIconsPopoverProps): JSX.Element {
  const sortedDangerGoods = () => {
    const set = new Set();
    return (
      dangerGoods
        // We only care about information in the `dangerGoodsType` property
        .map((item: DangerGood) => item.dangerGoodsType)
        // Remove duplicate entries, as we are only interested in the unique classifications
        // This uses a Set to look for duplicate values:
        .filter((item: DangerGoodsType) =>
          set.has(item.ridClass) ? false : set.add(item.ridClass),
        )
        // Sort the class based on its classification number from low to high
        .sort((a, b) => a.ridClass - b.ridClass)
    );
  };

  return (
    <Popover
      anchorEl={anchorElement}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={show}
      onClose={handleClose}
    >
      <Stack className={"p-2"} direction={"row"} spacing={2} onMouseLeave={handleClose}>
        {sortedDangerGoods().map((item, index) => (
          <JourneyDetailedDangerIcon dangerGoodsType={item} key={index} />
        ))}
      </Stack>
    </Popover>
  );
}
