import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Card, lighten, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { JourneyVehicleCard } from "../";
import { Vehicle } from "../../../models/Vehicle";
// eslint-disable-next-line no-unused-vars
import { Journey } from "../../../models/Journey";

interface JourneyVehicleListProps {
  data: Vehicle[];
  trainCompositionId: number;
  setTrainCompositionHandler: (trainCompositionId: number, id: number) => void;
  showEditMode: (item: Vehicle, selectedJourney: Journey) => boolean;
  getToken: () => void;
  selectedJourney: any;
}

/**
 * A reusable droppable place for the VehicleCards, it will either show all the cards that are draggable or it will
 * show a message mentioning that there isn't anything in the composition.
 *
 * @param {Array<Object>} data
 * @param {Number} trainCompositionId
 * @param {Function<void>} setTrainCompositionHandler
 * @param {boolean} showEditMode
 * @param {Function<void>} getToken
 * @param {Object} selectedJourney
 * @returns {JSX.Element}
 * @constructor
 */
export default function JourneyVehicleList({
  data,
  trainCompositionId,
  setTrainCompositionHandler,
  showEditMode,
  getToken,
  selectedJourney,
}: JourneyVehicleListProps): JSX.Element {
  const theme = useTheme<any>();

  return (
    <Droppable droppableId="droppable" direction="horizontal">
      {(provided: any, snapshot: any) => (
        <Card
          className={"p-3 d-flex flex-row"}
          sx={{
            bgcolor: snapshot.isDraggingOver
              ? theme.palette.primary.dark
              : lighten(theme.palette.primary.dark, 0.7),
            transition: "0.3s",
          }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {data.length === 0 && (
            <Typography color={theme.palette.primary.dark}>
              No train composition yet ...
            </Typography>
          )}
          {data.map((item, index) => (
            <JourneyVehicleCard
              getToken={getToken}
              showEditMode={showEditMode}
              key={item.id}
              item={item}
              index={index}
              trainCompositionId={trainCompositionId}
              setTrainCompositionHandler={setTrainCompositionHandler}
              selectedJourney={selectedJourney}
              iconNumber={1}
            />
          ))}
          {provided.placeholder}
        </Card>
      )}
    </Droppable>
  );
}
