// import { Stack } from "@mui/material";
import React from "react";
// import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
// import CheckIcon from "@mui/icons-material/Check";
// import StateCard from "./StateCard";
// import ErrorIcon from "@mui/icons-material/Error";

const TrainState = () => {
  return (
    <>
      {/* <Stack justifyContent="space-evenly" direction="row" spacing={3}> 
        <StateCard color="error.light" icon={<ErrorIcon color="error" className={"bg-white"} fontSize="medium"/>} title="TCM"  button={"Details"} description="Train Composition Message" status="Foutmelding"/>
        <StateCard color="text.secondary" icon={<QueryBuilderIcon className={"bg-white"} fontSize="medium"/>} title="PRM" button={"Verstuur PRM"} description="Path Request Message" status="Niet verzonden"/>
        <StateCard color="success.light" icon={<CheckIcon color="success" className={"bg-white"} fontSize="medium"/>} title="HLR" description="Hard Lockdown Request" status="Verzonden"/>
      </Stack> */}
    </>
  );
};

export default TrainState;
