import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import CustomerTable from "./table/CustomerTable";
import CustomerService from "../../api/customer";
import { roles } from "../../utils/constants";
import UserService from "../../api/user";
import ManageCustomer from "./ManageCustomer/ManageCustomer";
import { confirmAlert, errorAlert, succeedAlert } from "../../utils/Alerts";
import UserList from "./ManageCustomer/UserList";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import ManageUser from "../UserPicker/ManageUser/ManageUser";
import { useFetch } from "../../effects";
import { PageTransition, TitleBar } from "../../components";

export default function CustomerPicker() {
  const { user } = useAuth0();

  const [customers, setCustomers] = useState<{ data: any[]; isFetching?: boolean; error?: string }>(
    {
      data: [],
      isFetching: false,
      error: "",
    },
  );

  const [users, setUsers] = useState<{ data: any[]; isFetching?: boolean; error?: string }>({
    data: [],
    isFetching: false,
    error: "",
  });
  const { getTokenSilently } = useAuth0();

  /**
   * Booleans to regulate which components show up on tab Manage Customer
   * data & data2 to handle userDTO and customerDTO objects
   */
  const initSidebar = {
    showUserList: false,
    showCustomerTable: true,
    showCreateCustomer: false,
    showCreateUser: false,
    data: undefined,
    data2: undefined,
  };
  const [sidebar, setSidebar] = useState(initSidebar);
  const [rolelist, setRolelist] = useState(roles);

  useEffect(() => {
    if (sidebar.data) {
      const newObject = customers.data.find((item: any) => item.id === (sidebar.data as any).id);
      setSidebar((prevState) => ({ ...prevState, data: newObject }));
    }
  }, [customers, sidebar.data]);

  const fetchUsers = async (customerDTO: any) => {
    setUsers((prevState) => ({ ...prevState, isFetching: true }));
    try {
      if (getTokenSilently) {
        const { data, error } = await UserService.getAllByCustomerId(
          customerDTO.id,
          await getTokenSilently(),
        );
        if (data) {
          setUsers((prevState) => ({ ...prevState, isFetching: false, data }));
        } else {
          throw new Error(error);
        }
      }
    } catch (e: any) {
      setUsers((prevState) => ({ ...prevState, isFetching: false, error: e.message }));
    }
  };

  const generateRolelist = () => {
    let filteredRoles = roles.filter(
      (r) => r.value >= roles.find((r) => r.name === user["https://any-namespace/roles"][0])!.value,
    );
    setRolelist(filteredRoles);
  };

  useFetch(CustomerService.getAll, setCustomers);

  /**
   * Method to setting form to updating the customer
   * input customerDTO comes from row in customer table via edit button.
   */
  const onEditCustomer = (customerDTO: any, userDTO: any) => {
    fetchUsers(customerDTO);
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: true,
      showCustomerTable: false,
      showCreateCustomer: true,
      showCreateUser: false,
      data: customerDTO,
      data2: userDTO,
    }));
  };

  /**
   * Method to setting form to updating user from a customer
   * input userDTO comes from row in userlist show in edit customer view via edit button.
   */
  const onEditUser = (userDTO: any) => {
    generateRolelist();
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: true,
      showCustomerTable: false,
      showCreateCustomer: true,
      showCreateUser: true,
      data2: userDTO,
    }));
  };

  /**
   *
   *
   */
  const editUserHandler = () => {
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: true,
      showCustomerTable: false,
      showCreateCustomer: true,
      showCreateUser: false,
      data2: undefined,
    }));
  };

  /**
   * Method to add user to customer
   * set customerDTO as customer.id is necessary to create user and add to customer
   * leads to showCreateUser
   */
  const addUserHandler = (customerDTO: any) => {
    generateRolelist();
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: true,
      showCustomerTable: false,
      showCreateCustomer: true,
      showCreateUser: true,
      data: customerDTO,
    }));
  };

  /**
   * Method to delete user
   * input userDTO as userDTO.userId is needed for delete request
   * updatelist to update te list used in the userlist table
   */
  const onDeleteUser = async (userDTO: any) => {
    confirmAlert(async () => {
      if (getTokenSilently) {
        const deleteBody = {
          token: await getTokenSilently(),
          userid: userDTO.userId,
        };
        try {
          const result = await UserService.delete(deleteBody, await getTokenSilently());
          if (result.status === 202) {
            let updatedList = users.data.filter((u: any) => u.userId !== userDTO.userId);
            setUsers({ data: updatedList });
            succeedAlert();
          } else {
            errorAlert(result.error.message);
          }
        } catch (e: any) {
          errorAlert("Failed delete request " + e.message);
        }
      }
    });
  };

  /**
   * Method to delete customer
   * input userDTO as customer.id is needed for delete request
   * updatelist to update te list used in the customerTable
   */
  const onDeleteCustomer = async (customerDTO: any) => {
    confirmAlert(async () => {
      if (getTokenSilently) {
        const deleteBody = {
          token: await getTokenSilently(),
          customerid: customerDTO.id,
        };
        try {
          const result = await CustomerService.delete(deleteBody, await getTokenSilently());
          if (result.status === 202) {
            let updatedList = customers.data.filter((c) => c.id !== customerDTO.id);
            setCustomers({ data: updatedList });
            succeedAlert();
          } else {
            errorAlert(result.error.message);
          }
        } catch (e: any) {
          errorAlert("Failed delete request " + e.message);
        }
      }
    });
  };

  /**
   *
   */
  const addCustomerHandler = () => {
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: false,
      showCustomerTable: false,
      showCreateCustomer: true,
      showCreateUser: false,
      data: undefined,
    }));
  };

  /**
   * methode die gebruik word bij de close button
   * terug naar CustomerTable
   *
   * backToCustomerTable en backToEditCustomer worden gebruikt in een ternary functie
   */
  const backToCustomerTable = () => {
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: false,
      showCustomerTable: true,
      showCreateCustomer: false,
      showCreateUser: false,
      data: undefined,
    }));
  };

  /**
   * methode die gebruik word bij de close button
   * terug naar ManageCustomer
   *
   * backToCustomerTable en backToEditCustomer worden gebruikt in een ternary functie
   */
  const backToEditCustomer = () => {
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: true,
      showCustomerTable: false,
      showCreateCustomer: true,
      showCreateUser: false,
      data2: undefined,
    }));
  };

  /**
   * Als er in een edit customer window op add user word gedrukt word deze functie gebruikt
   * Deze leid naar een user create pagina waar customerId al prefilled is door de
   * meegegeven customerDTO
   *
   * @param {*} customerDTO
   */
  const addSuperUserHandler = (customerDTO: any) => {
    setSidebar((prevState) => ({
      ...prevState,
      showUserList: false,
      showCustomerTable: false,
      showCreateCustomer: false,
      showCreateUser: true,
      data: customerDTO,
    }));
  };

  if (customers.error) {
    return (
      <div className={"d-flex justify-content-center align-items-center w-100"}>
        Failed to fetch customers: {customers.error}
      </div>
    );
  }

  return (
    <PageTransition>
      <Box className={"h-100 w-100"}>
        {sidebar.showCustomerTable && (
          <TitleBar
            title={"Customers"}
            buttonMethod={addCustomerHandler}
            buttonText={"Add Customer"}
            permissions={["write:company"]}
          />
        )}
        {!sidebar.showCustomerTable && !sidebar.showCreateUser && (
          <TitleBar
            title={"Customer"}
            buttonMethod={sidebar.showCreateCustomer ? backToCustomerTable : backToEditCustomer}
            buttonText={"Close"}
            permissions={["write:company"]}
            icon={<Close />}
          />
        )}
        {!sidebar.showCustomerTable && sidebar.showCreateUser && (
            <TitleBar
                title={"Add or Edit User"}
                buttonMethod={sidebar.showCreateCustomer ? backToCustomerTable : backToEditCustomer}
                buttonText={"Close"}
                permissions={["write:company"]}
                icon={<Close />}
            />
        )}

        {sidebar.showCustomerTable && (
          <CustomerTable
            customers={customers.data}
            onEditCustomer={(row) => onEditCustomer(row, null)}
            onDeleteCustomer={(row) => onDeleteCustomer(row)}
            isFetching={customers.isFetching ? customers.isFetching : false}
          />
        )}
        {sidebar.showCreateCustomer && getTokenSilently && (
          <ManageCustomer
            getToken={async () => await getTokenSilently()}
            backToCustomerTable={() => backToCustomerTable()}
            addSuperUserHandler={(customerDTO) => addSuperUserHandler(customerDTO)}
            customerDTO={sidebar.data}
            onSave={setCustomers}
          />
        )}
        {sidebar.showUserList && (
          <UserList
            onEditUser={(row) => onEditUser(row)}
            onDeleteUser={(row) => onDeleteUser(row)}
            addUserHandler={(customerDTO) => addUserHandler(customerDTO)}
            customerDTO={sidebar.data}
            users={users.data}
            user={user.email}
          />
        )}
        {sidebar.showCreateUser && getTokenSilently && (
          <ManageUser
            rolelist={rolelist}
            getToken={async () => await getTokenSilently()}
            editUserHandler={() => editUserHandler()}
            onSave={setUsers}
            customerDTO={sidebar.data}
            userDTO={sidebar.data2}
            mode={"customer"}
          />
        )}
      </Box>
    </PageTransition>
  );
}
