import React, { useState, useMemo } from "react";
// eslint-disable-next-line no-unused-vars
import DataTable, { Alignment, TableColumn } from "react-data-table-component";
import Button from "react-bootstrap/Button";
import { hasPermissions } from "../../../utils/scopeChecker";
import {Stack, SvgIcon} from "@mui/material";
import { Filter, Spinner } from "../../../components";
import iconNames from "../../../utils/icons/SVGIcons";

interface Wagon {
  numberFreight: string;
  code: string;
  weightEmpty: number;
}

interface Props {
  wagons: Wagon[];
  onEditWagon: (wagon: Wagon) => void;
  onDeleteWagon: (wagon: Wagon) => void;
  isFetching: boolean;
}

export default function WagonTable({ wagons, onEditWagon, onDeleteWagon, isFetching }: Props) {
  const columns: TableColumn<Wagon>[] = [
    {
      name: "Icon",
      cell: (row: Wagon) => (
          <SvgIcon fontSize="large">
            {iconNames["wagons"][parseInt(row.code)]()}
          </SvgIcon>
      ),
      selector: (row: Wagon) => parseInt(row.code),  // Primitive value for sorting
      sortable: true,
    },
    {
      name: "Number",
      selector: (row : any) => row.numberFreight,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row: any) => row.typeName,
      sortable: true,
    },
    {
      name: "Empty Weight (tonnes)",
      selector: (row: any) => row.weightEmpty,
      sortable: true,
    },
  ];

  const getColumns = (): TableColumn<Wagon>[] => {
    if (hasPermissions(["write:wagon"])) {
      const buttonsColumn: TableColumn<Wagon> = {
        cell: (row: any) => (
          <Stack spacing={1} direction={"row"}>
            <Button className={["text-nowrap"].join(" ")} variant="outline-danger" size="sm" onClick={() => onDeleteWagon(row)}>
              Delete
            </Button>
            <Button className={["text-nowrap"].join(" ")} variant="outline-secondary" size="sm" onClick={() => onEditWagon(row)}>
              Edit
            </Button>
          </Stack>
        ),
        ignoreRowClick: true,
        right: true,
      };

      return [...columns, buttonsColumn];
    } else {
      return columns;
    }
  };

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const filteredWagons = wagons.filter(
    (wagon) => wagon.numberFreight && wagon.numberFreight.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} placeholder={"Filter by Freight Number"} />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <DataTable
      columns={getColumns()}
      defaultSortFieldId="scheduledTimeAtHandover"
      data={filteredWagons}
      pagination
      paginationResetDefaultPage={resetPaginationToggle}
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      subHeaderAlign={Alignment.LEFT}
      persistTableHead
      highlightOnHover
      noHeader={true}
      pointerOnHover={true}
      progressPending={isFetching}
      progressComponent={<Spinner />}
    />
  );
}
