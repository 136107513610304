import { ENDPOINTS } from "../utils/constants";
import FetchService from "./fetchservice";

class TrainCompositionService extends FetchService {
  getComposition(trainCompositionId: number, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINCOMPOSITIONS}/${trainCompositionId}`, "GET", token)
      .then((data: Response) => super.parseJSON(data))
      .catch((error: Error) => Promise.reject(new Error(error.message)));
  }

  saveStock(trainCompositionId: number, body: any, token: string) {
    return super
      .fetch(`${ENDPOINTS.TRAINCOMPOSITIONS}/${trainCompositionId}/stock`, "POST", token, body)
      .then((data: Response) => super.parseJSON(data))
      .catch((error: Error) => Promise.reject(new Error(error.message)));
  }

  updateStock(trainCompositionId: number, stockId: number, body: any, token: string) {
    return super
      .fetch(
        `${ENDPOINTS.TRAINCOMPOSITIONS}/${trainCompositionId}/stock/${stockId}`,
        "PUT",
        token,
        body,
      )
      .then((data: Response) => super.parseJSON(data))
      .catch((error: Error) => Promise.reject(new Error(error.message)));
  }

  deleteStock(trainCompositionId: number, stockId: number, token: string) {
    return super
      .fetch(
        `${ENDPOINTS.TRAINCOMPOSITIONS}/${trainCompositionId}/stock/${stockId}`,
        "DELETE",
        token,
      )
      .then((data: Response) => super.parseJSON(data))
      .catch((error: Error) => Promise.reject(new Error(error.message)));
  }

  moveStock(trainCompositionId: number, stockId: number, body: any, token: string) {
    return super
      .fetch(
        `${ENDPOINTS.TRAINCOMPOSITIONS}/${trainCompositionId}/stock/${stockId}/move`,
        "PUT",
        token,
        body,
      )
      .then((data: Response) => super.parseJSON(data))
      .catch((error: Error) => Promise.reject(new Error(error.message)));
  }
}

export default new TrainCompositionService();
