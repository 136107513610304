import React, { useCallback, useEffect, useState } from "react";
import { hot } from "react-hot-loader/root";
import TrainPicker from "./page/TrainPicker/TrainPicker";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PATH } from "./utils/constants";
import "bootstrap/dist/css/bootstrap.min.css";
import TrainDetails from "./page/TrainDetails/TrainDetails";
import TractionPicker from "./page/TractionPicker/TractionPicker";
import WagonPicker from "./page/WagonPicker/WagonPicker";
import { useAuth0 } from "./react-auth0-spa";
import userService from "./api/user";
import CompanyPicker from "./page/CompanyPicker/CompanyPicker";
import UserPicker from "./page/UserPicker/UserPicker";
import CustomerPicker from "./page/CustomerPicker/CustomerPicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";
import { PageLoader, SidebarDrawer } from "./components/";
import ErrorPage from "./page/ErrorPage/ErrorPage";
import {log} from "@craco/craco/dist/lib/logger";

let theme = createTheme({
  palette: {
    primary: {
      main: "#4c52bc",
      light: "#fff",
      dark: grey[600],
    },
    secondary: {
      main: "#9ba0f7",
      light: "#8f94b8",
    },
  },
  components: {
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          "&::before": {
            display: "none",
          },
        },
      },
    },
  },
});

const App = () => {
  const { loading, loginWithRedirect, getTokenSilently, isAuthenticated, user } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [error, setError] = useState(null);

  /**
   * Zet de permissions uit auth0 token in localStorage var scopes.
   */
  const getToken = useCallback(async () => {
    const token = await getTokenSilently();
    const scopes = JSON.parse(atob(token.split(".")[1])).permissions;
    localStorage.setItem("scopes", JSON.stringify(scopes));
    localStorage.setItem("token", token);
    setUserRoles(scopes);

    try {
      const { data } = await userService.getCurrent(token);
      setCurrentUser(data);
    } catch (error) {
      setError(error.message);
    }
  }, [getTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && userRoles.length === 0) {
      getToken();
    } else if (!isAuthenticated && !loading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, getToken, userRoles, loading, loginWithRedirect]);

  return (
    <ThemeProvider theme={theme}>
      <PageLoader isLoading={loading && Object.keys(currentUser).length === 0} noPadding>
        <Box className={"h-100 w-100 d-flex"}>
          <BrowserRouter>
            <SidebarDrawer currentUser={currentUser} />
            <Routes>
              {!loading && Object.keys(currentUser).length !== 0 && (
                <>
                  <Route path={PATH.TRAINS} element={<TrainPicker />} />
                  {/* We only want to redirect if Auth0 is done with authenticating */}
                  <Route path={"/"} element={<Navigate to={user['https://any-namespace/roles'][0] === 'Admin' ? PATH.CUSTOMERS : PATH.TRAINS} />} />
                  <Route path={"/index.html"} element={<Navigate to={user['https://any-namespace/roles'][0] === 'Admin' ? PATH.CUSTOMERS : PATH.TRAINS} />} />
                  <Route path={PATH.TRAINDETAILS} element={<TrainDetails />} />
                  <Route path={PATH.TRACTIONS} element={<TractionPicker />} />
                  <Route path={PATH.WAGONS} element={<WagonPicker />} />
                  <Route path={PATH.COMPANIES} element={<CompanyPicker />} />
                  <Route path={PATH.CUSTOMERS} element={<CustomerPicker />} />
                  <Route path={PATH.USERS} element={<UserPicker />} />
                </>
              )}
              {error && (
                <>
                  <Route path={"/"} element={<Navigate to={PATH.ERROR} />} />
                  <Route path={"/index.html"} element={<Navigate to={PATH.ERROR} />} />
                </>
              )}
              <Route path={PATH.ERROR} element={<ErrorPage error={error} />} />
            </Routes>
          </BrowserRouter>
        </Box>
      </PageLoader>
    </ThemeProvider>
  );
};

export default hot(App);
