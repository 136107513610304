import { ENDPOINTS } from "../utils/constants";
import FetchService from "./fetchservice";

class UserService extends FetchService {
  getAll(token: string) {
    return super
      .fetch(`${ENDPOINTS.USERS}`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  getAllByCustomerId(customerId: number, token: string) {
    return super
      .fetch(`${ENDPOINTS.USERS}/customer/${customerId}`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  getCurrent(token: string) {
    return super
      .fetch(`${ENDPOINTS.USERS}/current`, "GET", token)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  save(token: string, body: any) {
    return super
      .fetch(`${ENDPOINTS.USERS}`, "PUT", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  update(token: string, body: any) {
    return super
      .fetch(`${ENDPOINTS.USERS}`, "PATCH", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }

  delete(body: any, token: string) {
    return super
      .fetch(`${ENDPOINTS.USERS}`, "DELETE", token, body)
      .then((data) => super.parseJSON(data))
      .catch((error) => Promise.reject(new Error(error.message)));
  }
}

export default new UserService();
