import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { ErrorRounded } from "@mui/icons-material";
import { PageTransition } from "../../components";
import { useNavigate } from "react-router-dom";

interface ErrorPageProps {
  error?: string;
}

export default function ErrorPage({ error }: ErrorPageProps) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!error) {
      navigate("/");
    }
  }, []);

  return (
    <PageTransition>
      <Stack className={"d-flex align-items-center"} direction={"column"} spacing={1}>
        <Stack direction={"row"} spacing={1}>
          <ErrorRounded color={"error"} />
          <Typography className={"fw-bold"}>{error}</Typography>
        </Stack>
        <Typography variant={"subtitle2"}>Please try again later...</Typography>
      </Stack>
    </PageTransition>
  );
}
